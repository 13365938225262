import React from "react";

const Paginator = ({previous, next, currentPage, currentNumberPerPage, resultSize}) => {
  if(!resultSize || resultSize < 1 || currentNumberPerPage >= resultSize) {
    return null;
  }
  return (
    <div className="paginator">
      {currentPage > 1 && <button type="button" className="previous" onClick={previous}>&laquo; Previous</button>}
      {currentPage*(currentNumberPerPage + 1) < resultSize && <button type="button" className="next" onClick={next}>Next &raquo;</button>}
    </div>
  );
};

export default Paginator;
