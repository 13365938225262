import React, { useState } from "react";
import { approveProcess } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import NumberPadWithToggle from "./numberPadWithToggle";

const ActionApprove = ({processId, form, setForm}) => {

  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }
  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  const approveNow = (employeeCode) => {
    approveProcess({id: processId,
      employeeCode: employeeCode,
      callbackFailure,
      callbackSuccess: result => {
        const fm = deepCopy(form);
        for(let x=0; x < fm.data.processes.length; x++) {
          if(fm.data.processes[x].id === processId) {
            fm.data.processes[x].approvalTime =  result.process.approval_time;
            fm.data.processes[x].approvedBy = result.user.id;
            fm.data.processes[x].approvedByUser = {firstName: result.user.first_name, lastName: result.user.last_name};
          }
        }
        callbackSuccess();
        setForm(fm);
    }});
  };

  return (
    <NumberPadWithToggle
      onSubmit={approveNow}
      errorMessage={errorMessage}
      isVisibleNumberPad={isVisibleNumberPad}
      closeNumberPad={closeNumberPad}
      openNumberPad={openNumberPad}
      btnText="Approve"
      btnCssClass="next-step"
    />
  );
};
export default ActionApprove;
