import { useState, useEffect } from "react";
import { postBatchPpe, postBatchCaution, postBatchMixing, postBatchBom, getDictionary } from "../../api/Api";

const SettingsPage = () => {
  const [ppe, setPpe] = useState('');
  const [caution, setCaution] = useState('');
  const [mixing, setMixing] = useState('');
  const [saveStatus, setSaveStatus] = useState('');
  const [failedBoms, setFailedBoms] = useState([]);

  useEffect(() => {

    getDictionary({callbackSuccess: (result) => {
      setPpe(result.data.ppe.map(item => item.ppe).join("\n"));
      setCaution(result.data.caution.map(item => item.caution).join("\n"));
      setMixing(result.data.mixing.map(item => item.instruction).join("\n"));
    }});

  }, []);

  const callbackSuccess = _ => setSaveStatus('success');
  const callbackFailure = _ => setSaveStatus('error');

  const postBomFile = () => {
    const input = document.querySelector('#bom-file');

    const formData = new FormData();
    formData.append('bom_file', input.files[0])
    postBatchBom({formData, callbackSuccess: result => {
      setSaveStatus(result.failures.length > 0 ? 'error' : 'success');
      setFailedBoms(result.failures);
    }, callbackFailure});
  };

  return (
      <div id="settings" className="form-page">
        <h1>Manage Settings</h1>
        {saveStatus === 'success' && (
          <div className="success-dialog">
            <span>Success!</span>
            <button type="button" className="close" onClick={_=>setSaveStatus('')}>Close</button>
          </div>
        )}
        {saveStatus === 'error' && (
          <div className="error-dialog">
            <span>Error: Please review erroros below.</span>
            <button type="button" className="close" onClick={_=>setSaveStatus('')}>Close</button>
          </div>
        )}
        <div className="form-field">
          <label htmlFor="bom-file">BOM File:</label>
          <input id="bom-file" type="file" />
          <button type="button" className="btn" onClick={_ => postBomFile()}>Upload</button>
        </div>
        <div className="form-field">
          <label>PPE:</label>
          <textarea value={ppe} onChange={e => setPpe(e.currentTarget.value)}></textarea>
          <button className="btn" type="button" onClick={_ => postBatchPpe({data:ppe, callbackSuccess, callbackFailure})}>Save PPE</button>
        </div>
        <div className="form-field">
          <label>Caution:</label>
          <textarea value={caution} onChange={e => setCaution(e.currentTarget.value)}></textarea>
          <button className="btn" type="button" onClick={_ => postBatchCaution({data:caution, callbackSuccess, callbackFailure})}>Save Caution</button>
        </div>
        <div className="form-field">
          <label>Timed Instructions:</label>
          <textarea value={mixing} onChange={e => setMixing(e.currentTarget.value)}></textarea>
          <button className="btn" type="button" onClick={_ => postBatchMixing({data:mixing, callbackSuccess, callbackFailure})}>Save Instructions</button>
        </div>
        {failedBoms.length > 0 && (
          <div className="error-dialog">
            <h2 style={{marginBottom:"2em"}}>Failed BOM Imports</h2>
            {failedBoms.map((row,i) => (
              <div key={i} style={{marginBottom:"2em"}}>
                {JSON.stringify(row.row)}
              </div>
            ))}
          </div>
        )}
      </div>
  );
};

export default SettingsPage;
