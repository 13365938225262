import React from "react";

const PageIndicator = ({currentPage, currentNumberPerPage, resultSize}) => {
  if(!resultSize || resultSize < 1 || currentNumberPerPage >= resultSize) {
    return null;
  }
  return (<p className="page-indicator">Page {currentPage} of {Math.ceil(resultSize / currentNumberPerPage)}</p>);
};

export default PageIndicator;
