import { deepCopy } from '../helpers/deepCopy';
import { objCamelToSnake } from '../helpers/variableNotation';
import { getCookie } from '../helpers/cookies';
const url = process.env.REACT_APP_API_URL;

const HTTP_CODES = {
  SUCCESS: 200,
  SUCCESS_CREATE: 201,
  BAD_METHOD: 400,
  NOT_AUTHORIZED: 401,
  NOT_FOUND: 404,
  NOT_PROCESSABLE: 422,
  INTERNAL_SERVER: 500
};

const activate = async ({
  token,
  callbackSuccess,
  callbackFailure
}) => {
  const promise = await fetch(`${url}/activate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({token})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess();
  else if (callbackFailure)
    callbackFailure(result);
};

const register = async ({
  data,
  callbackSuccess,
  callbackFailure
}) => {
  const promise = await fetch(`${url}/register`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess();
  else if (callbackFailure)
    callbackFailure(result);
};

const reset = async ({email, password, token, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/reset`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({email, password, token})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const forgot = async ({email, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/forgot`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({email})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const login = async ({email, password, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/authenticate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({email, password})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const getDictionary = async ({callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/app`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const listVesselStatus = async ({callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/vessel`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const findUsers = async ({query, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/user?${query}`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const updateUser = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/user/${data.id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const getMe = async ({callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/me`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const putMe = async ({
  account,
  callbackSuccess,
  callbackFailure
}) => {
  const promise = await fetch(`${url}/me`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(account)})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const findProcesses = async ({query, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process?${query}`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const getProcess = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process/${id}`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const allStepsInOne = async ({id, employeeCode, allStepsInOne, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process-all-steps-in-one/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode, allStepsInOne})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const updatePwiProcess = async ({id, data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const approveProcess = async ({id, employeeCode, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process-setup/approve/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const beginProcess = async ({id, employeeCode, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process-setup/begin/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const completeProcess = async ({id, employeeCode, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process-setup/complete/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const saveProcessStep = async ({data, callbackSuccess, callbackFailure}) => {
  const dTemp = deepCopy(data);
  dTemp.pwiProcessId = dTemp.processId;
  delete dTemp.processId;
  const promise = await fetch(`${url}/pwi-process-step`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(dTemp)})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const incompleteProcessStep = async ({id, employeeCode, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process-step-incomplete/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

/*
// NOTE: Without employee code, not being used set
const completeProcessStep = async ({processId, stepSlug, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/complete-process-step`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({processId, stepSlug})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
*/
const addMaterial = async ({employeeCode, qty, unit, pwiProcessId, stepSlug, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/add-material`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode, qty, unit, pwiProcessId, stepSlug})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const addPpeEmployee = async ({employeeCode, pwiProcessId, stepSlug, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/add-ppe-employee`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode, pwiProcessId, stepSlug})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const updateProcess = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process/${data.id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const createProcess = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const cloneProcess = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process-clone`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({id})})
    }
  );
  const result = await promise.json();
  if([HTTP_CODES.SUCCESS_CREATE, HTTP_CODES.SUCCESS].includes(promise.status) && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const deleteProcess = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const archiveProcess = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/process-archive/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const findProducts = async ({query, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/product?${query}`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const findWorkOrders = async ({query, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order?${query}`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};
const getWorkOrder = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order/${id}`, {
      method: 'GET',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const createWorkOrder = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const activateWorkOrder = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order-setup/activate/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const cancelWorkOrder = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order-setup/cancel/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const beginWorkOrder = async ({id, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order-setup/start/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      }
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const completeWorkOrder = async ({id, employeeCode, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/work-order-setup/complete/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({employeeCode})})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const initProcess = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/pwi-process`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake(data)})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const postBatchBom = async ({formData, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/batch/bom`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
      },
      body: formData
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const postBatchPpe = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/batch/ppe`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({data})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const postBatchMixing = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/batch/mixing`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({data})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const postBatchCaution = async ({data, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/batch/caution`, {
      method: 'POST',
      headers: {
        'Authorization':`Bearer ${getCookie('token')}`,
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({data})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS_CREATE && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

const getProfile = async ({accessCode, urlSegment, callbackSuccess, callbackFailure}) => {
  const promise = await fetch(`${url}/profile`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify({...objCamelToSnake({accessCode,urlSegment})})
    }
  );
  const result = await promise.json();
  if(promise.status === HTTP_CODES.SUCCESS && callbackSuccess)
    callbackSuccess(result);
  else if (callbackFailure)
    callbackFailure(result);
};

export {
  // User
  login,
  forgot,
  reset,
  activate,
  register,
  getMe,
  putMe,
  findUsers,
  updateUser,

  // Process
  cloneProcess,
  findProcesses,
  getProcess,
  createProcess,
  updateProcess,
  deleteProcess,
  archiveProcess,

  // Product
  findProducts,

  // Workorder
  createWorkOrder,
  findWorkOrders,
  getWorkOrder,
  activateWorkOrder,
  cancelWorkOrder,
  beginWorkOrder,
  completeWorkOrder,


  // Vessel
  listVesselStatus,

  // PwiProcess
  initProcess,
  beginProcess,
  completeProcess,
  approveProcess,
  allStepsInOne,
  updatePwiProcess,

  // ProcessStep
  //completeProcessStep,
  incompleteProcessStep,
  saveProcessStep,
  addPpeEmployee,
  addMaterial,

  // Configure
  postBatchBom,
  postBatchCaution,
  postBatchPpe,
  postBatchMixing,
  getProfile,
  getDictionary
};
