// TODO: not sure if this is the best check, but in the db, any dates greater than 1970 is considered not null
import { adminProcessCanEdit } from "../../../helpers/permissions";

const isArchived = date => date && !date.match(/^1970/);

const isReadOnly = (formData, role) => {
  const reasons = [];
  if(isArchived(formData.archived)) {
    reasons.push("archived");
  }
  if(formData.usedByWorkOrder) {
    reasons.push("used-by-work-order");
  }
  if(!adminProcessCanEdit(role)) {
    reasons.push("not-authorized");
  }
  return reasons;
};
export {
  isArchived,
  isReadOnly
};
