import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { findWorkOrders, activateWorkOrder, cancelWorkOrder, listVesselStatus } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import Paginator from "../../common/paginator";
import PageIndicator from "../../common/pageIndicator";
import { isArchived } from "../../pwi/edit/helper";

// const now = (new Date()).toISOString().replace(/T/, ' ').replace(/\.\d\d\dZ/, '');
const longTimeAgo = '2000-01-01 00:00:00';

const pageSettings = {
  active: {
    title: 'Active PWIs',
    query: `o=schedule_time&d=asc&activate_time_gte=${longTimeAgo}&end_time=&cancel_time=`,
  },
  scheduled: {
    title: 'Scheduled PWIs',
    query: `o=schedule_time&d=asc&activate_time=&cancel_time=`,
  },
  completed: {
    title: 'Completed PWIs',
    query: `o=schedule_time&d=desc&end_time_lt=2050-01-0 00:00:00&cancel_time=`,
  },
  aborted: {
    title: 'Aborted PWIs',
    query: `o=schedule_time&d=desc&cancel_time_lt=2050-01-0 00:00:00`,
  },
};

const vesselIsAvailable = (vesselSlug, vessels) => {
  const stat = vessels.filter(ves => ves.vessel === vesselSlug);
  if(stat.length < 1) {
    return true;
  }
  const [ lastProd ] = stat;
  return lastProd.end_time || lastProd.cancel_time;
};

const showVesselStatus = (vesselSlug, vessels) => {

  const stat = vessels.filter(ves => ves.vessel === vesselSlug);
  if(stat.length < 1) {
    return <span>Available</span>;
  }
  const [ lastProd ] = stat;
  let statusMessage = '';
  if(lastProd.end_time) {
    statusMessage = 'Last Completed : ' + lastProd.product_name;
  } else if (lastProd.cancel_time) {
    statusMessage = 'Last Aborted : ' + lastProd.product_name;
  } else {
    statusMessage = 'In Production: ' + lastProd.product_name;
  }
  return <span>{statusMessage}</span>;
};

const WorkOrders = () => {
  const defaultPage = 1;
  const defaultNumberPerPage = 20;
  const defaultSearchParams = { p: defaultPage, n: defaultNumberPerPage, size:0, productName:"", workOrder: "", bom:"", referenceNumber:""};
  const [ showSearch, setShowSearch ] = useState(false);
  const { query } = useParams();
  const [ searchParams, setSearchParams ] = useState(defaultSearchParams);
  const [workOrders, setWorkOrders] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [isActivating, setIsActivating] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelId, setCancelId] = useState(0);
  //const [pageSort, setPageSort] = useState({ p: defaultPage, n: defaultNumberPerPage, size: 0 });

  const onEditSearch = (field, val) => {
    const sParams = deepCopy(searchParams);
    sParams[field] = val;
    setSearchParams(sParams);
  }
  const search = searchArgs => {
    setWorkOrders([]);
    const findPwiSuccess = (result) => {
      setWorkOrders(deepCopy(result.data));
      const sParams = deepCopy(searchArgs);
      sParams.size = parseInt(result.size);
      setSearchParams(sParams);
    };
    const searchQuery = pageSettings[query].query +
      '&n=' + searchArgs['n'] +
      '&p=' + searchArgs['p'] +
      (searchArgs.workOrder.trim() && '&work_order=' + encodeURIComponent(searchArgs.workOrder.trim())) +
      (searchArgs.productName.trim() && '&product_name=' + encodeURIComponent(searchArgs.productName.trim())) +
      (searchArgs.bom.trim() && '&bom=' + encodeURIComponent(searchArgs.bom.trim())) +
      (searchArgs.referenceNumber.trim() && '&reference_number=' + encodeURIComponent(searchArgs.referenceNumber.trim()));
    findWorkOrders({
      query: searchQuery,
      callbackSuccess: findPwiSuccess,
    });
  };

  useEffect(() => {
    setWorkOrders([]);
    findWorkOrders({
      query: pageSettings[query].query + '&n=' + defaultNumberPerPage + '&p=' + defaultPage,
      callbackSuccess: (result) => {
        setWorkOrders(deepCopy(result.data));
        setSearchParams({
          n: defaultNumberPerPage,
          p: defaultPage,
          size: parseInt(result.size),
          productName: '',
          workOrder: '',
          bom: '',
          referenceNumber: ''
        });
      },
    });

    listVesselStatus({callbackSuccess: (result) => setVessels(deepCopy(result.data))});
  }, [query]);

  const clickActivateWorkOrder = (id) => {
    setIsActivating(true);
    activateWorkOrder({
      id,
      callbackSuccess: (_) => listVesselStatus({
          callbackSuccess: (result) => {
            setIsActivating(false);
            setVessels(deepCopy(result.data))
        }
      })
    });
  };
  const confirmCancelWorkOrder = (id) => {
    setCancelId(id);
  };
  const clickCancelWorkOrder = (id) => {
    setIsCancelling(true);
    cancelWorkOrder({
      id,
      callbackSuccess: (_) => listVesselStatus({
          callbackSuccess: (result) => {
            setIsCancelling(false);
            setCancelId(0);
            setVessels(deepCopy(result.data))
        }
      })
    });
  };
  return (
    <div className="table">
      <h2>{pageSettings[query].title}</h2>
      <button type="button" className="btn-show-search" onClick={()=>setShowSearch(!showSearch)}>
        <span className={"icon-" + (showSearch ? "down-open" : "right-open")}></span>
        <span>Search</span>
      </button>
      {showSearch && (
      <div className="ctrl-search">
        <label>Product Name</label>
        <input type="text" value={searchParams.productName} onChange={e=>onEditSearch('productName',e.currentTarget.value)} />
        <label>Work Order</label>
        <input type="text" value={searchParams.workOrder} onChange={e=>onEditSearch('workOrder',e.currentTarget.value)} />
        <label>BOM</label>
        <input type="text" value={searchParams.bom} onChange={e=>onEditSearch('bom',e.currentTarget.value)} />
        <label>Ref</label>
        <input type="text" value={searchParams.referenceNumber} onChange={e=>onEditSearch('referenceNumber',e.currentTarget.value)} />
        <button type="button" className="btn-search" onClick={()=>search({
            n: defaultNumberPerPage,
            p: defaultPage,
            size: 0,
            productName: searchParams.productName,
            workOrder: searchParams.workOrder,
            bom: searchParams.bom,
            referenceNumber: searchParams.referenceNumber,
          })
          }>
          <span className="icon-search"></span>
          <span>Search</span>
        </button>
      </div>
      )}
      <PageIndicator currentPage={searchParams.p} currentNumberPerPage={searchParams.n} resultSize={searchParams.size} />
      <table>
        <thead>
          <tr>
            <th>Vessel</th>
            <th>Product</th>
            <th>BOM</th>
            <th>Ref</th>
            <th>Work Order</th>
            <th>Schedule Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {workOrders.map((row, k) => (
            <tr key={k}>
              <td>{row.vessel}</td>
              <td>
                <span className="field-main">{row.product_name}</span>
                <span className="field-sub">
                  <strong className="field-sub-label">Version:</strong>
                  <span className="field-sub-value">{row.version_number} {row.version_note}</span>
                </span>
                {['scheduled'].includes(query) && (
                <span className="field-sub">
                  <strong className="field-sub-label">Vessel Status</strong>
                  <span className="field-sub-value">{showVesselStatus(row.vessel, vessels)}</span>
                </span>
                )}
                {!['scheduled'].includes(query) && isArchived(row.archived) && (
                <span className="field-sub">
                  <strong className="field-sub-label">Archived On</strong>
                  <span className="field-sub-value">{row.archived.replace(/.\d\d:.*$/,'')}</span>
                </span>
                )}
              </td>
              <td>{row.bom}</td>
              <td>{row.reference_number}</td>
              <td>{row.work_order}</td>
              <td>{row.schedule_time.replace(/.\d\d:.*$/,'')}</td>
              <td className="action">
              {['active', 'completed', 'aborted'].includes(query) && <Link className="icon-search" to={`/work-order/view/${row.id}`}><span className="tip">View</span></Link>}
              {!['active', 'completed', 'aborted'].includes(query) && <Link className="icon-search" to={`/work-order/${row.id}`}><span className="tip">View</span></Link>}
              {['scheduled','active'].includes(query) && <button className="icon-block" type="button" onClick={_ => confirmCancelWorkOrder(row.id)}><span className="tip">Cancel</span></button>}
              {['scheduled'].includes(query) && vesselIsAvailable(row.vessel, vessels) && !isActivating && <button className="btn-action" type="button" onClick={_ => clickActivateWorkOrder(row.id)}>Activate</button>}
              </td>
            </tr>
          ))}
          {workOrders.length < 1 && (
            <tr>
              <td colSpan="7">No results</td>
            </tr>
          )}
        </tbody>
      </table>
      <Paginator
        currentPage={searchParams.p}
        currentNumberPerPage={searchParams.n}
        resultSize={searchParams.size}
        next={()=>{const sParams = deepCopy(searchParams); sParams.p = sParams.p + 1; search(sParams);}}
        previous={()=>{const sParams = deepCopy(searchParams); sParams.p = sParams.p - 1; search(sParams);}}
      />
      {cancelId > 0 && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" type="button" onClick={_=>setCancelId(0)}>&#10006;</button>
            <h3 className="modal-title">Are you sure you want to abort this work order?</h3>
            <div className="modal-controls">
            {isCancelling && <button type="button">Cancelling...</button>}
            {!isCancelling && <button type="button" onClick={_=>clickCancelWorkOrder(cancelId)}>Yes, abort</button>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrders;
