
const SwitchButton = ({readOnly, checked, onChange, labels}) => {

  let onEvt = {};
  let offEvt = {};
  if(onChange && !readOnly) {
    onEvt = {
      onKeyPress: ()=>onChange(true),
      onClick: ()=>onChange(true),
    };
    offEvt = {
      onKeyPress: ()=>onChange(false),
      onClick: ()=>onChange(false),
    };
  }
  return (
    <div className={"switch-button" + (checked ? ' on' : ' off') + (readOnly ? ' read-only' : '')}>
      <span className="on" role="button" tabIndex="0" {...onEvt}>{labels && labels.yes ? labels.yes : 'Yes'}</span>
      <span className="off" role="button" tabIndex="0" {...offEvt}>{labels && labels.no ? labels.no : 'No'}</span>
    </div>
  );
};

export default SwitchButton;
