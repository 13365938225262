import React from "react";
import SwitchButton from "../../../common/switchButton";
import DeleteStep from "./deleteStep";
import { deepCopy } from "../../../../helpers/deepCopy";
import { fieldFinder, editor } from "./fieldHelper";

const editorInput = {
  production: (inputSlug, value, form, setForm, pathHandle) => {
    const fm = deepCopy(form);
    const inputIndex = fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex].inputs.findIndex(input => input.slug === inputSlug);
    fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex].inputs[inputIndex].value = value;
    setForm(fm);
  },

};

const BooleanStep = ({form, setForm, pathHandle}) => {

  const field = fieldFinder[pathHandle.mode](form, pathHandle);

  const edit = value => {
    editor[pathHandle.mode](value, form, setForm, pathHandle);
  };

  const editInput = (inputSlug, value) => {
    editorInput[pathHandle.mode](inputSlug, value, form, setForm, pathHandle);
  };

  const moreThanOneInputs = field.value && "inputs" in field && Array.isArray(field.inputs) && field.inputs.length > 1;
  const hasInputs = field.value && "inputs" in field && Array.isArray(field.inputs) && field.inputs.length > 0;
  return (
    <div className={"process-field boolean "+(moreThanOneInputs ? 'multi-inputs' : '')}>
      <div className="main-field">
        <label>{field.label}</label>
        <SwitchButton onChange={value => edit(value)} checked={field.value} />
      </div>
      {hasInputs && (
        <div className="optional-inputs">
          {field.inputs.filter(input => ["email", "number", "text"].includes(input.type.admin)).map(input => (
          <div className="optional-input" key={input.slug}>
            <label>{input.label.admin}</label>
            <input type={input.type.admin ? input.type.admin : "text"} onChange={e => editInput(input.slug, e.currentTarget.value)} value={input.value} />
          </div>
          ))}
        </div>
      )}
      <DeleteStep form={form} setForm={setForm} pathHandle={pathHandle} />
    </div>
  );
};

export default BooleanStep;
