import { Redirect, useParams } from "react-router-dom";
import { createProcess, updateProcess } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../helpers/variableNotation";
import PwiNav from "./pwiNav";
import Process from "./process";
import { isReadOnly } from "./helper";

const Production = ({form, setForm, role}) => {

  const { processNumber, id } = useParams();
  const processIndex = parseInt(processNumber);
  const nextIndex = processIndex + 1;
  const prevIndex = processIndex - 1;

  const nextUrl = [ "pwi" ];
  const prevUrl = [ "pwi" ];
  if(id) {
    nextUrl.push(id);
    prevUrl.push(id);
  }
  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.saved = true;
    setForm(fm);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };
  const save = async () => {
    const saveToAPI = form.data.id ? updateProcess : createProcess;
    saveToAPI({data:form.data, callbackSuccess, callbackFailure});
  }
  nextUrl.push(nextIndex >= form.data.production.processes.length ? "post-production" : "production/"+nextIndex);
  prevUrl.push(prevIndex < 0 ? "pre-production" : "production/"+prevIndex);
  if(form.saved) {
    return <Redirect to="/pwi/list/pending" />;
  }

  return (
    <div>
      <Process form={form} setForm={setForm} processIndex={processIndex} />
      <PwiNav
        save={isReadOnly(form.data, role).length > 0 ? null : ()=>save()}
        prev={'/'+prevUrl.join('/')}
        next={'/'+nextUrl.join('/')}
      />
    </div>
  );
};

export default Production;
