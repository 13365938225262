import React, { useState } from "react";
import { getStep } from "../helpers";
import { addMaterial } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../../helpers/variableNotation";
import NumberPadWithToggle from "./numberPadWithToggle";

export const saveMaterial = ({form, setForm, processMode, processName, stepSlug, employeeCode, qty, unit, callbackFailure, callbackSuccess}) => {

  const process = form.data.processes ? form.data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;

  let step = getStep(process, stepSlug);
  if(!step) {
    step = {
      processId: process.id,
      content: {slug: stepSlug, additions:[]}
    }
  }
  addMaterial({
    employeeCode,
    qty,
    unit,
    stepSlug,
    pwiProcessId: process.id,
    callbackFailure,
    callbackSuccess: result => {
      const resultCamel = objSnakeToCamel(result);
      const fm = deepCopy(form);
      for(let x in fm.data.processes) {
        if(fm.data.processes[x].processMode === processMode && fm.data.processes[x].processName === processName) {

          if(!step.id) {
            step = resultCamel.data;
            //step.startTime = (new Date()).toISOString();
            fm.data.processes[x].steps.push(step);
          } else {
            // re-apply the save --- not very intuitive, fix later
            for(let y in fm.data.processes[x].steps) {
              //if(fm.data.processes[x].steps[y].content.options.find(option => option.slug === optionSlug)) {
              if(fm.data.processes[x].steps[y].id === step.id) {
                fm.data.processes[x].steps[y] = resultCamel.data;
              }
            }
          }
        }
      }
      setForm(fm);
      callbackSuccess(result);
    }
  });
};

const AddMaterial = ({ form, setForm, processName, processMode, stepSlug, readOnly, deactivate }) => {

  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ addError, setAddError ] = useState('');
/*
  const { data } = form;
  const process = data.processes ? data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;
  // console.log(process, data.processes, stepSlug, processMode, processName);

  const completed = process.steps.find(step => step.content.slug === stepSlug && step.completedBy);
  if(completed) {
    return <div className="vessel-action-done"><button type="button" className="readonly">Done</button></div>;
  }
*/
  const [ qty, setQty ] = useState(0);
  const [ unit, setUnit ] = useState('kg');
  const [ showModal, setShowModal ] = useState(false);

  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }

  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  if(readOnly) {
    return null;
  }

  if(showModal) {
    return (
      <div className="modal add-material">
        <div className="modal-content">
          <button type="button" className="modal-close" onClick={()=>setShowModal(false)}>&times;</button>
          <label>Qty</label>
          <input type="number" pattern="\d*" onChange={e=>{setAddError('');setQty(e.currentTarget.value);}} value={qty} />
          <label>Unit</label>
          <select onChange={e=>setUnit(e.currentTarget.value)} value={unit}>
            <option value="kg">Kilograms</option>
            <option value="bag">Bags/Pails</option>
          </select>
          {addError && <div className="error-message">{addError}</div>}
          {parseFloat(qty) > 0 ? (
          <div className="modal-control">
            <NumberPadWithToggle btnText="Submit"
              isVisibleNumberPad={isVisibleNumberPad}
              closeNumberPad={closeNumberPad}
              openNumberPad={openNumberPad}
              errorMessage={errorMessage}
              onSubmit={employeeCode => saveMaterial({
                  form,
                  setForm,
                  processName,
                  processMode,
                  stepSlug,
                  employeeCode,
                  qty,
                  unit,
                  callbackFailure,
                  callbackSuccess
                })
              }
            />
          </div>
          ) : (
            <div className="modal-control"><button className="readonly" onClick={()=>setAddError("You must enter a positive quantity")}>Submit</button></div>
          ) }
        </div>
      </div>
    );
  }
  if(deactivate && deactivate.state) {
    return <button type="button" className="readonly" onClick={()=>alert(deactivate.message)}>Partial Complete</button>;
  }
  return <button type="button" onClick={()=>setShowModal(true)}>Partial Complete</button>;
};
export default AddMaterial;
