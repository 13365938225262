import { useState } from "react";
import { completeStep, incompleteStep } from "../helpers";
import NumberPadWithToggle from "./numberPadWithToggle";

export const isStepComplete = (processSteps, stepSlug) => processSteps.find(step => step.content.slug === stepSlug && step.completedBy);

const CompleteStep = ({ form, setForm, processName, processMode, stepSlug, readOnly, disableEdit, deactivate }) => {


  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const { data } = form;
  const process = data.processes ? data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;

  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }

  const completed = isStepComplete(process.steps, stepSlug);
  if(completed && readOnly) {
    return <div className="vessel-action-done"><button type="button" className="readonly">Done</button></div>;
  }

  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  if(completed) {
    if(disableEdit) {
      return (
        <div className="vessel-action-done">
          <button className="readonly" onClick={()=>{}}>Done</button>
        </div>
      );
    }
    const saveInCompleteStep = employeeCode => {
      setErrorMessage('');
      incompleteStep({form, setForm, processName, processMode, stepSlug, employeeCode, callbackFailure, callbackSuccess});
    };
    return (
      <div className="vessel-action-done">
        <NumberPadWithToggle
          btnText="Edit"
          isVisibleNumberPad={isVisibleNumberPad}
          closeNumberPad={closeNumberPad}
          openNumberPad={openNumberPad}
          errorMessage={errorMessage}
          onSubmit={saveInCompleteStep}
          btnCssClass="make-incomplete"
        />
      </div>
    );
  }

  if(readOnly) {
    return null;
  }

  if(deactivate && deactivate.state)  {
    return (
      <div className="vessel-action-done">
        <button className="readonly" onClick={()=>alert(deactivate.message)}>Done</button>
      </div>
    );
  }

  const saveCompleteStep = employeeCode => {
    setErrorMessage('');
    completeStep({form, setForm, processName, processMode, stepSlug, employeeCode, callbackFailure, callbackSuccess});
  };

  return (
    <div className="vessel-action-done">
      <NumberPadWithToggle
        btnText="Done"
        isVisibleNumberPad={isVisibleNumberPad}
        closeNumberPad={closeNumberPad}
        openNumberPad={openNumberPad}
        errorMessage={errorMessage}
        onSubmit={saveCompleteStep}
    />
    </div>
  );
};
export default CompleteStep;
