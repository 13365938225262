import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { initProcess } from "../../../api/Api";
import modes from "../../../config/productionModes";
import { deepCopy } from "../../../helpers/deepCopy";
import { saveStep, getOption } from "./helpers";
import CompletedBy from "./action/completedBy";
import CompleteStep, { isStepComplete }  from "./action/completeStep";
import AddPpeEmployee from "./action/addPpeEmployee";
import AddMaterial from "./action/addMaterial";
import PreviewMaterial from "./action/previewMaterial";
import BeginProcess from "./action/beginProcess";
import AllStepsInOneProcess from "./action/allStepsInOne";
import CompleteProcess from "./action/completeProcess";
import ApproveProcess from "./action/approveProcess";
import Notes from "./action/notes";
import TimeCounter from "./timeCounter";
import ProcessSignOff from "./processSignOff";

import OtherInputTimer from "./otherInput/timer";

const validChargeFields = (process, step) => {
  if(!('inputs' in step && step.inputs.length > 0)) {
    return true;
  }
  const valids = step.inputs.filter(input => parseFloat(getOption(process, step.slug, input.slug).value) > 0);
  return valids.length === step.inputs.length;
};

const emptyChargeFields = (process, step) => {
  if(!('inputs' in step && step.inputs.length > 0)) {
    return true;
  }
  const valids = step.inputs.filter(input => parseFloat(getOption(process, step.slug, input.slug).value) === 0 || !getOption(process, step.slug, input.slug).value);
  return valids.length === step.inputs.length;
};

const areRequiredCompleted = (processSchemaSteps, processSteps) => {
  const rateSteps = processSchemaSteps.filter( step => {
      // TODO: need to get rid of the yes/no ... use the X
      const isBooleanInAdmin = step && step.type && step.type.admin && ['boolean', 'mixing', 'manualAdditionRate'].includes(step.type.admin);
      return ((isBooleanInAdmin && step.value) || !isBooleanInAdmin)
      && step.config
      && step.config.terminal
      && step.config.terminal.includes("requires-complete");
  });
  const completedRateSteps = processSteps.filter(
    (step, stepIndex) => {
      return step.content
      && 'slug' in step.content
      && step.content.slug.toString().length > 0
      && processSchemaSteps[parseInt(step.content.slug)]
      && processSchemaSteps[parseInt(step.content.slug)].config
      && processSchemaSteps[parseInt(step.content.slug)].config.terminal
      && processSchemaSteps[parseInt(step.content.slug)].config.terminal.includes("requires-complete")
      && isStepComplete(processSteps, step.content.slug)
    }
  );
/*
console.log(processSchemaSteps.filter( step => step.config && step.config.terminal && step.config.terminal.includes("requires-complete")).length, completedRateSteps.length);
console.log(processSchemaSteps.filter( step => step.config && step.config.terminal && step.config.terminal.includes("requires-complete")).map(s=>s.value), completedRateSteps.length);
console.log(processSchemaSteps.filter( step => step.config && step.config.terminal && step.config.terminal.includes("requires-complete")).map(s=>s.type), completedRateSteps.length);
console.log(rateSteps.length, completedRateSteps.length);
*/
  return rateSteps.length <= completedRateSteps.length;
};

const isAllStepsInOneCompleted = process => ('allStepsInOne' in process) && process.allStepsInOne;

const getTimeDiff = (dateA, dateB) => {

  const diff = (dateA.getTime() - dateB.getTime())/1000;
  const hrs = Math.floor(diff / 3600);
  const modHrs = diff % 3600;
  const mins = Math.floor(modHrs / 60);
  const secs = Math.floor(modHrs % 60);

  return `${hrs.toString().padStart(2,'0')}:${mins.toString().padStart(2,'0')}:${secs.toString().padStart(2,'0')}`;
};
const isPpeAccepted = (processSchema, process) => {

  const ppeIndex = processSchema.steps.findIndex(step => step.type.terminal === 'ppe');

  // no ppe requirement, hence success
  if(ppeIndex < 0) {
    return true;
  }

  // no ppe sign off exists, hence fail
  const ppeStepValues = process.steps.find(s=>s.content.slug.toString() === ppeIndex.toString());
  if(!ppeStepValues) {
    return false;
  }

  if(!(ppeStepValues.content.users
    && Array.isArray(ppeStepValues.content.users)
    && ppeStepValues.content.users.length > 0
  )) {
    return false;
  }

  const maxPpeDate = ppeStepValues
    .content
    .users
    .reduce((carry,item) => {
      const d = typeof item.date === 'string'
        ? new Date(item.date.replace(/ /, 'T')+'.000Z')
        : item.date;

      if(!carry) {
        return d;
      }
      if(d.getTime() > carry) {
        return d;
      }
      return carry;
    },'');

  // no addition rate requirement, so no shift rotation requirement, hence success
  const additionIndex = processSchema.steps.findIndex(step => step.type.terminal === 'manualAdditionRate');
  if(additionIndex < 0) {
    return true;
  }

  // no shift rotation recorded, hence success
  const additionStepValues = process.steps.find(s=>s.content.slug.toString() === additionIndex.toString());
  if(!( additionStepValues
    && additionStepValues.content
    && additionStepValues.content.additions
    && Array.isArray(additionStepValues.content.additions)
    && additionStepValues.content.additions.length > 0
  )) {
    return true;
  }

  const maxAdditionDate = additionStepValues
    .content
    .additions
    .reduce((carry,item) => {
      const d = typeof item.date === 'string'
        ? new Date(item.date.replace(/ /, 'T')+'.000Z')
        : item.date;

      if(!carry) {
        return d;
      }
      if(d.getTime() > carry) {
        return d;
      }
      return carry;
    },'');

  // There has been a PPE agreement since last addition time
  return maxPpeDate.getTime() > maxAdditionDate.getTime();
};
/*
const ActionProgress = ({form, setForm, processName, stepSlug}) => {
  const { data } = form;
  const process = data.processes ? data.processes.find(row => row.processMode === modes.production && row.processName === processName) : null;

  const completed = process.steps.find(step => step.content.slug === stepSlug && step.completedBy);
  if(completed) {
    if(completed.endTime && completed.startTime) {
      return (
        <div className="vessel-action-progress">
          <div className="duration completed">{getTimeDiff(new Date(completed.endTime.replace(/\s/,'T') + ".000Z"), new Date(completed.startTime.replace(/\s/,'T') + ".000Z"))}</div>
        </div>
      );
    }
    return null;
  }

  const started = process.steps.find(step => step.content.slug === stepSlug && step.startTime);
  if(started) {
    return (
      <div className="vessel-action-progress">
        <TimeCounter startTime={new Date(started.startTime.replace(/\s/,'T') + ".000Z")} />
      </div>
    );
  }

  return (
    <div className="vessel-action-progress">
      <button onClick={_=>saveStep({form, setForm, processName, processMode:modes.production, stepSlug, startTime:new Date()})}>Start</button>
    </div>
  );
};
*/
const ProcessProgress = ({form, setForm, processName, processMode, readOnly}) => {
  const { data } = form;
  const process = data.processes ? data.processes.find(row => row.processMode === modes.production && row.processName === processName) : null;

  if(process.endTime && process.startTime) {
    return (
      <div className="vessel-action-process-progress">
        <div className="duration completed"><span>{getTimeDiff(new Date(process.endTime.replace(/\s/,'T') + ".000Z"), new Date(process.startTime.replace(/\s/,'T') + ".000Z"))}</span></div>
      </div>
    );
  }

  if(process.startTime) {
    return (
      <div className="vessel-action-process-progress">
        <TimeCounter startTime={new Date(process.startTime.replace(/\s/,'T') + ".000Z")} tzOffset={form.dictionary?.constants?.timezone?.offset} />
      </div>
    );
  }

  return (
    <div className="vessel-action-process-progress">
      <BeginProcess form={form} setForm={setForm} processId={process.id} readOnly={readOnly} />
    </div>
  );
};

const PpeStep = ({process, step, stepIndex, actionDoneProps, readOnly, dictionary}) => {

  const processStepValues = process.steps.find(s=>s.content.slug.toString() === stepIndex.toString());
  const doneBy = processStepValues ? processStepValues.content.users.map(u => u.date + ' - ' + u.firstName + ' ' + u.lastName) : [];
  const stepTexts = step.value.map(val => {
    if('ppe' in dictionary && dictionary.ppe.filter( ppeVal => ppeVal.slug === val).length > 0) {
       return dictionary.ppe.filter(ppeVal => ppeVal.slug === val)[0].ppe;
    }
    return val;
  });
  return (
    <div className="vessel-step requires-complete">
      <div className="vessel-step-details">
        <h3>{step.label}</h3>
        <p className="vessel-step-value">{stepTexts.join(', ')}</p>
        {doneBy.length > 0 && (
          <div className="other-list">
            <span className="other-list-title">Done by:</span>
            <ul>
            {doneBy.map(row => <li key={row}>{row}</li>)}
            </ul>
          </div>
        )}
      </div>
      <AddPpeEmployee {...actionDoneProps} stepSlug={stepIndex.toString()} readOnly={readOnly} />
    </div>
  );
};

const AddMaterialStep = ({form, setForm, processName, material, process, step, stepIndex, actionDoneProps, ppeAccepted, readOnly}) => {

  const processStepValues = process.steps.find(s=>s.content.slug.toString() === stepIndex.toString());

  const units = {
    bag: {singular: 'bag/pail', plural:'bags/pails'},
    kg: {singular:'kg', plural:'kgs'}
  };

  return (
    <div className={"vessel-step requires-complete "+step.slug}>
      <div className="vessel-step-details">
        <h3>{step.label}</h3>
        <p className="vessel-step-value">Addition Rate: {step.time} min{parseInt(step.time) > 1 ? 's': ''} / {step.qty} bag{parseInt(step.qty) > 1 ? 's': ''} or pail{parseInt(step.qty) > 1 ? 's': ''}</p>
        {processStepValues && processStepValues.content.additions && (
          <div className="other-list">
            <span className="other-list-title">Partial Additions:</span>
            <ul>
              {processStepValues.content.additions.map(row => (
                <li key={row.date}>{row.date} - {row.qty} {units[row.unit][row.qty === 1 ? 'singular' : 'plural']} by {row.employee.firstName} {row.employee.lastName}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="vessel-action-partial">
        {ppeAccepted && <AddMaterial {...actionDoneProps} stepSlug={stepIndex} readOnly={readOnly || isStepComplete(process.steps, stepIndex)} deactivate={{state:!emptyChargeFields(process, step), message:'Delete totals and charge to allow manual addition.'}} />}
      </div>
      {OtherInputs(form, setForm, processName, process, step, stepIndex, readOnly, ppeAccepted)}
      {ppeAccepted && <CompleteStep {...actionDoneProps} stepSlug={stepIndex} readOnly={readOnly} deactivate={{state:!validChargeFields(process, step), message:'Must provide valid totals and charge'}} />}
      {CompletedBy(process, stepIndex)}
    </div>
  );
};
const OtherInputsErrors = (process, step) => {

  if(!('inputs' in step && step.inputs.length > 0)) {
    return true;
  };

  const errs = step
    .inputs
    .filter(input => input.config && input.config.terminal && input.config.terminal.includes("required") && getOption(process, step.slug, input.slug).value.toString() === "")
    .map(input => ({label: input.label.terminal}));
  return errs;
};

const OtherInputs = (form, setForm, processName, process, step, stepIndex, readOnly, ppeAccepted) => {
  if(!('inputs' in step && step.inputs.length > 0)) {
    return null;
  }
  const otherInputArgs = {
    form, setForm, processName, process, step, stepIndex, readOnly, ppeAccepted
  };

  return (
    <div className="other-input-fields">
      {step.inputs.map( input => (
      <div key={input.slug} className="other-input">
        {input.value.toString().length > 0 && (
          <div className="other-input-field">
            <label>{input.label.admin}</label>
            <label>{input.value}</label>
          </div>
        )}
        {['number', 'text', 'email'].includes(input.type.terminal) && (
        <div className="other-input-field">
          <label>{input.label.terminal}</label>
          <input type={input.type.terminal}
            className={input.slug}
            pattern={input.type.terminal === 'number' ? '\\d*' : null}
            value={getOption(process, step.slug, input.slug).value}
            onChange={ evt => saveStep({
                form,
                setForm,
                processMode:modes.production,
                processName,
                stepSlug: step.slug,
                optionValue: evt.currentTarget.value,
                optionSlug: input.slug
              })}
            readOnly={readOnly || isStepComplete(process.steps, stepIndex) || !ppeAccepted}
          />
        </div>
        )}
        {['textarea'].includes(input.type.terminal) && (
        <div className="other-input-field">
          <label>{input.label.terminal}</label>
          <textarea
            className={input.slug}
            value={getOption(process, step.slug, input.slug).value}
            onChange={ evt => saveStep({
                form,
                setForm,
                processMode:modes.production,
                processName,
                stepSlug: step.slug,
                optionValue: evt.currentTarget.value,
                optionSlug: input.slug
              })}
            readOnly={readOnly || isStepComplete(process.steps, stepIndex) || !ppeAccepted}
          ></textarea>
        </div>
        )}
        <OtherInputTimer input={input} {...otherInputArgs} />
      </div>
    ))}
    </div>
  );
};

const Production = ({ form, setForm, viewSettings, processNumber }) => {
  const { processNumber: processNumberParams } = useParams();
  const processNum = typeof processNumber !== 'undefined' ? parseInt(processNumber).toString(): processNumberParams;
  const processName = 'production-'+processNum;

  const { data } = form;
  const dictionary = data.pwi?.dictionary ? data.pwi.dictionary : {};

  const process = data.processes ? data.processes.find(row => row.processMode === modes.production && row.processName === processName) : null;

  useEffect(() => {
    if(data.id && !process) {
      initProcess({
        data:{
          workOrderId: data.id,
          processMode: modes.production,
          processName,
        },
        callbackSuccess: result => {
          const fm = deepCopy(form);
          fm.data.processes.push({workOrderId: data.id, processName: processName, processMode: modes.production, id: result.data, steps: []});
          setForm(fm);
        }
      })
    }
  }, [data.id, process, processName, form, setForm]);

  if(!(data.id && process)) {
    return null;
  }

  const processSchema = data.pwi.production.processes[parseInt(processNum)];

  const nextProcessNumber = parseInt(processNum) + 1;
  const nextLink = data.pwi.production.processes.length > nextProcessNumber ? `/production/${nextProcessNumber}` : `/post-production`;

  const prevProcessNumber = parseInt(processNum) - 1;
  const prevLink = prevProcessNumber < 0 ? '' : `/production/${prevProcessNumber}`;

  const successUrl = `${viewSettings.baseUrl}/${data.id}${nextLink}`;
  const actionApproveProps = {form, setForm, processId: process.id, successUrl};

  const actionDoneProps = { form, setForm, processName, processMode:modes.production };
  // const actionStartProps = { form, setForm, processName };

  const cautions = processSchema.steps.filter(step => step.type.terminal === 'caution');

  const requiresEmployeeCode = step =>
      step.config &&
      step.config.terminal &&
      step.config.terminal.includes('requires-employee-code');

  const requiresSupervisorApproval = steps => steps
    .find(step => step.type.terminal === 'supervisorApproval' && step.value);

  const ppeAccepted = isPpeAccepted(processSchema, process);
  const getMixingInstruction = (dict, mixSlug) => {
    if('mixing' in dict && dict.mixing.filter(mix=> mix.slug === mixSlug).length > 0 ) {
      return dictionary.mixing.filter(mix=> mix.slug === mixSlug)[0].instruction;
    }
    return mixSlug;
  }

  const getCaution = (dict, cautionSlug) => {
    if('caution' in dict && dict.caution.filter(caut => caut.slug === cautionSlug).length > 0 ) {
      return dictionary.caution.filter(caut => caut.slug === cautionSlug)[0].caution;
    }
    return cautionSlug;
  }

  const readOnly = viewSettings.mode === 'admin' || process.approvedBy || process.completedBy;
  const materialCodeLabel = Boolean(parseInt(form.data.pwi.isOrganic)) ? ` - No. ${processSchema.code}` : null;
  return (
    <React.Fragment>
      <h2>Production: Process {parseInt(processNum) + 1} of {data.pwi.production.processes.length}</h2>
      <div className="vessel-step-container production">
        <AllStepsInOneProcess
          processId={process.id}
          form={form}
          setForm={setForm}
          readOnly={readOnly}
        />
        <div className="vessel-step">
          <div className="vessel-step-details">
            <h3 className="material-title">Material{materialCodeLabel}</h3>
            <p className="vessel-step-value">{processSchema.material}</p>
            {cautions.map(caut => <p key={caut.slug} className="vessel-step-caution">Caution: {getCaution(dictionary, caut.value)}</p>)}
            {process.startTime && <div className="other-list"><div className="other-list-title">Started @ {process.startTime.toString()}</div></div>}
          </div>
          <ProcessProgress form={form} setForm={setForm} processName={processName} processMode={modes.production} readOnly={readOnly} />
        </div>
        {processSchema.steps.map((step, stepIndex) => (
          <React.Fragment key={step.slug}>
          {["checkboxList", "ppe"].includes(step.type.terminal) && (
            <PpeStep
              dictionary={dictionary}
              process={process}
              step={step}
              stepIndex={stepIndex}
              actionDoneProps={actionDoneProps}
              readOnly={readOnly}
            />
          )}
          </React.Fragment>
          )
        )}
        {processSchema.steps.map((step, stepIndex) => (
          <React.Fragment key={step.slug}>
          {["manualAdditionRate"].includes(step.type.terminal) && step.value &&
            <AddMaterialStep
              form={form}
              setForm={setForm}
              processName={processName}
              process={process}
              step={step}
              stepIndex={stepIndex}
              actionDoneProps={actionDoneProps}
              material={processSchema.material}
              ppeAccepted={ppeAccepted}
              readOnly={readOnly}
            />}
          {["autoAdditionRate"].includes(step.type.terminal) && step.value && (
            <div className="vessel-step requires-complete">
              <div className="vessel-step-details">
                <h3>{step.label}</h3>
                <p className="vessel-step-value">Automatic Addition</p>
                {OtherInputs(form, setForm, processName, process, step, stepIndex, readOnly, ppeAccepted)}
                {CompletedBy(process, stepIndex)}
              </div>
              { requiresEmployeeCode(step)
                && ppeAccepted
                && <CompleteStep {...actionDoneProps}
                    stepSlug={stepIndex}
                    readOnly={readOnly}
                    deactivate={{state:!validChargeFields(process, step), message:"Provide a valid charge"}}
                /> }
            </div>
          )}
          {["boolean"].includes(step.type.terminal) && step.value && (
            <div className={"vessel-step " + step.slug + (step.config && step.config.terminal && step.config.terminal.includes("requires-complete") ? " requires-complete" : "")}>
              <div className="vessel-step-details">
                <h3>{step.label}</h3>
                {OtherInputs(form, setForm, processName, process, step, stepIndex, readOnly, ppeAccepted)}
                {CompletedBy(process, stepIndex)}
              </div>
              { requiresEmployeeCode(step)
                && ppeAccepted
                && <CompleteStep {...actionDoneProps}
                  stepSlug={stepIndex}
                  readOnly={readOnly}
                  deactivate={{
                    state: OtherInputsErrors(process, step).length > 0,
                    message: OtherInputsErrors(process, step).length > 0 ? "Invalid fields: " + OtherInputsErrors(process, step).map(err => err.label).join(", ") : ""
                  }}
                /> }
            </div>
          )}
          {["mixing"].includes(step.type.terminal) && (
            <div className={"vessel-step " + step.slug + (step.config && step.config.terminal && step.config.terminal.includes("requires-complete") ? " requires-complete" : "")}>
              <div className="vessel-step-details">
                <h3>{step.label}</h3>
                <p className="vessel-step-value">{getMixingInstruction(dictionary, step.value)}</p>
                {OtherInputs(form, setForm, processName, process, step, stepIndex, readOnly, ppeAccepted)}
                {CompletedBy(process, stepIndex)}
              </div>
              { requiresEmployeeCode(step)
                  && <CompleteStep
                      {...actionDoneProps}
                      stepSlug={stepIndex}
                      disableEdit={true}
                      readOnly={readOnly}
                      deactivate={{
                        state: OtherInputsErrors(process, step).length > 0,
                        message: OtherInputsErrors(process, step).length > 0 ? "Invalid fields: " + OtherInputsErrors(process, step).map(err => err.label).join(", ") : ""
                      }}
              /> }
            </div>
          )}
          {["text","number","email","dropdown"].includes(step.type.terminal) && (
            <div className={"vessel-step " + step.slug + (step.config && step.config.terminal && step.config.terminal.includes("requires-complete") ? " requires-complete" : "")}>
              <div className="vessel-step-details">
                <h3>{step.label}</h3>
                <p className="vessel-step-value">{step.value}</p>
                {CompletedBy(process, stepIndex)}
              </div>
              { requiresEmployeeCode(step) && ppeAccepted && <CompleteStep {...actionDoneProps} stepSlug={stepIndex} readOnly={readOnly} /> }
            </div>
          )}
          </React.Fragment>
        ))}
        <Notes
          processId={process.id}
          form={form}
          setForm={setForm}
          readOnly={readOnly}
        />
      </div>
      <PreviewMaterial form={form} processNum={parseInt(processNum)+1} />
      {viewSettings.mode !== 'admin' && (
      <div className="vessel-navigation-bottom">
        <NavLink className="button previous-step" to={`/vessel/${data.id}${prevLink}`}>&laquo; Previous</NavLink>
        {!process.completedBy
          && areRequiredCompleted(processSchema.steps, process.steps)
          && ppeAccepted
          && isAllStepsInOneCompleted(process)
          &&  <CompleteProcess form={form} setForm={setForm} processId={process.id} />}
        {(process.approvedBy || !requiresSupervisorApproval(processSchema.steps)) && process.completedBy && ppeAccepted && <NavLink className="button next-step" to={successUrl}>Next &raquo;</NavLink>}
        {process.completedBy
            && requiresSupervisorApproval(processSchema.steps)
            && !process.approvedBy
            && ppeAccepted
            && <ApproveProcess {...actionApproveProps} />}
        {!process.approvedBy && requiresSupervisorApproval(processSchema.steps) && <p className="supervisor-approval-required">*Supervisor approval required</p>}
        <ProcessSignOff process={process} />
      </div>
      )}
    </React.Fragment>
  );
};
export default Production;
