import React from "react";
import DeleteStep from "./deleteStep";
import { deepCopy } from "../../../../helpers/deepCopy";
import { fieldFinder } from "./fieldHelper";

const toggler = {
  production: (slug, form, setForm, pathHandle) => {
    const fm = deepCopy(form);

    const foundIndex = fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex].value.indexOf(slug);
    if(foundIndex > -1) {
      fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex].value.splice(foundIndex, 1);
    } else {
      fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex].value.push(slug);
    }
    setForm(fm);
  }
};

const CheckboxList = ({form, setForm, options, pathHandle}) => {

  const field = fieldFinder[pathHandle.mode](form, pathHandle);

  const toggleCheckbox = (slug) => {
    toggler[pathHandle.mode](slug, form, setForm, pathHandle);
  };
  return (
    <div className="process-field checkbox-list">
      <label>{field.label}</label>
      <ul>
      {options.map(opt => (
        <li key={opt.slug}>
          <input id={opt.slug+pathHandle.processIndex+pathHandle.fieldIndex} type="checkbox" onChange={e => toggleCheckbox(opt.slug) } checked={field.value.includes(opt.slug)} />
          <label htmlFor={opt.slug+pathHandle.processIndex+pathHandle.fieldIndex}>{opt.label}</label>
        </li>
      ))}
      </ul>
      <DeleteStep form={form} setForm={setForm} pathHandle={pathHandle} />
    </div>
  );
};

export default CheckboxList;
