import { useState } from "react";
import { forgot } from "../../api/Api";
import { deepCopy } from "../../helpers/deepCopy";
import { _e } from "../../helpers/validation";
import { objSnakeToCamel } from "../../helpers/variableNotation";

import Footer from "../../components/footer/footer";

const ForgotPage = () => {
  const [form, setForm] = useState({
    email: "",
    error: {},
    sent: false,
  });
  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.sent = true;
    setForm(fm);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };

  const edit = (fieldName, val) => {
    const fm = deepCopy(form);
    fm[fieldName] = val;
    setForm(fm);
  };
  return (
    <div className="signuppage">
      <form
        className="signup-form"
        onSubmit={(e) => {
          e.preventDefault();
          forgot({ email: form.email , callbackSuccess, callbackFailure });
        }}
      >
        <div className="form-head">
          <h1 className="head-text">Reset Your Password</h1>
        </div>
        <div className="form-body">
          {form.sent
            ? (
          <div className="container">
            <p className="register-confirm">Please check your email for instructions to reset your password.</p>
          </div>
            )
            : (
          <div className="container">
            {Object.keys(form.error).length > 0 && (
              <p className="error-message">Review form below for errors.</p>
            )}
            <label className="input-label" id="emailLabel" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              className="input-value"
              type="text"
              placeholder="Email"
              value={form.email}
              onChange={(e) => edit("email", e.currentTarget.value)}
            />
            {_e(form.error.email)}
            <div className="form-buttons">
              <button className="signup-button btn" type="submit">
                Email Instructions
              </button>
            </div>
          </div>)}
        </div>
      </form>
      <section className="footer-section">
        <Footer />
      </section>
    </div>
  );
};

export default ForgotPage;
