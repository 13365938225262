import React, { useState } from "react";

import defaultSteps from "./defaultSteps";

import { deepCopy } from "../../../helpers/deepCopy";
/*
const isLiquid = (code) => {
  return code && code.toUpperCase().match(/^FLB/);
};
*/
const AddStep = ({processIndex, addLocation, form, setForm}) => {

  const [ showAddStep, setShowAddStep ] = useState('');
  const [ newStepSelected, setNewStepSelected ] = useState(0);

  //const process = form.data.production.processes[processIndex];

  const availableSteps = deepCopy(defaultSteps);
  const addStep = () => {
    const fm = deepCopy(form);
    const newStep = deepCopy(availableSteps[newStepSelected]);
    newStep.slug += (new Date()).getTime();
    if(showAddStep === 'end') {
      fm.data.production.processes[processIndex].steps.push(newStep);
    } else {
      fm.data.production.processes[processIndex].steps.unshift(newStep);
    }
    setForm(fm);
    setShowAddStep('');
  };
  return (
    <React.Fragment>
      {showAddStep && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" type="button" onClick={_=>setShowAddStep('')}>&times;</button>
            <h3>Add new field:</h3>
            <select onChange={e => setNewStepSelected(e.currentTarget.value)} value={newStepSelected}>
              {availableSteps.map((s, index) => <option key={s.label} value={index}>{s.label}</option>)}
            </select>
            <div className="modal-controls">
              <button type="button" onClick={_=>addStep()}>Add</button>
            </div>
          </div>
        </div>
      )}
      <button className="add-button" type="button" onClick={_=>setShowAddStep(addLocation)}>Add Step</button>
    </React.Fragment>
  );
};

export default AddStep;
