import React from "react";
import SortItems from "../../common/sortItems";
import Input from "./fields/input";
import Bool from "./fields/boolean";
import DropDown from "./fields/dropdown";
import CheckboxList from "./fields/checkboxList";
import AdditionRate from "./fields/additionRate";
import AddStep from "./addStep";
import { deepCopy } from "../../../helpers/deepCopy";

const Process = ({processIndex, form, setForm}) => {

  const processNumber = processIndex + 1;

  if(processIndex >= form.data.production.processes.length) {
    return null;
  }

  const process = form.data.production.processes[processIndex];

  const renderStep = (step, stepIndex) => {
    const fieldProps = {
      form,
      setForm,
      pathHandle: {
        mode: "production",
        processIndex,
        fieldIndex: stepIndex
      }
    };
    return (
      <React.Fragment>
        {["text","number","email"].includes(step.type.admin) && <Input {...fieldProps} textType={step.type.admin} />}
        {step.type.admin === "mixing" && <DropDown {...fieldProps} options={form.data.dictionary.mixing.map(o=>({slug:o.slug, label:o.instruction}))} />}
        {step.type.admin === "caution" && <DropDown {...fieldProps} options={form.data.dictionary.caution.map(o=>({slug:o.slug, label:o.caution}))} />}
        {step.type.admin === "boolean" && <Bool {...fieldProps} />}
        {step.type.admin === "ppe" && <CheckboxList {...fieldProps} options={form.data.dictionary.ppe.map(o=>({slug:o.slug, label:o.ppe}))} />}
        {step.type.admin === "manualAdditionRate" && <AdditionRate {...fieldProps} />}
      </React.Fragment>
    );
  };
  const setItems = (items) => {
    const fm = deepCopy(form);
    fm.data.production.processes[processIndex].steps = items;
    setForm(fm);
  };

  return (
    <div className="pwi-field-management">
      <h3>Process {processNumber} of {form.data.production.processes.length}</h3>
      <div className="pwi-field-list">
        <AddStep form={form} setForm={setForm} processIndex={processIndex} addLocation="start" />
        <div className="production-material">
          <label>Raw Material -</label>
          <span>{process.material}</span>
          <span className="production-material-code">{process.code}</span>
        </div>
        <SortItems
          items={form.data.production.processes[processIndex].steps}
          setItems={setItems}
          idHandle="slug"
          renderContent={renderStep}
        />
      </div>
      <AddStep form={form} setForm={setForm} processIndex={processIndex} addLocation="end" />
    </div>
  );
};

export default Process;
