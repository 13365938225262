import modes from "../../../../config/productionModes";
import { saveStep, getStep, getOption, getTimeDiff } from "../helpers";
import { isStepComplete }  from "../action/completeStep";

const OITimer = ({input, form, setForm, process, processName, step, stepIndex, readOnly, ppeAccepted}) => {

  if(!['timer'].includes(input.type.terminal)) {
    return null;
  }
  // Show Start Button
  const optVal = getOption(process, step.slug, input.slug).value;
  if(optVal.toString().length < 1) {
    const btnReadOnly = readOnly || isStepComplete(process.steps, stepIndex) || !ppeAccepted;
    const cssClassName = ["duration", input.slug, btnReadOnly ? "readonly" : ""].join(" ");
    const onClick = evt => saveStep({
      form,
      setForm,
      processMode:modes.production,
      processName,
      stepSlug: step.slug,
      optionValue: '###SYSTEMTIME###',
      optionSlug: input.slug
    });
    return (
      <div className="other-input-field timer">
        <button
          className={cssClassName}
          onClick={btnReadOnly ? () => {} : onClick} 
        >Start Timer</button>
      </div>
    );
  }
  const stepVal = getStep(process, stepIndex);
  const endTime = stepVal && stepVal.endTime ? new Date(stepVal.endTime.replace(/\s/,'T').replace(/\.\d\d\dZ$/,'') + ".000Z") : new Date();


  let startTime = new Date();
  let tzOffset = 0;
  if(optVal && optVal === '###SYSTEMTIME###') {
    startTime = new Date();
  } else if (optVal && optVal.match(/\d\d\d\d-\d\d-\d\d/)) {
    startTime = new Date(optVal.replace(/\s/,'T') + ".000Z");
    tzOffset = form.dictionary.constants?.timezone?.offset;
  }
  //console.log("stepval and stepvalEnd --", stepVal && stepVal.endTime, "optval --", optVal, "end --", endTime, "start --", startTime);

  if(isStepComplete(process.steps, stepIndex)) {
    return <div className="other-input-field timer"><div className={"duration "+input.slug}>{getTimeDiff(endTime, startTime)}</div></div>
  }
  return (
    <div className="other-input-field timer">
      <iframe title="clock" className={"duration "+input.slug} src={"/clock.html?start_time="+optVal+"&tz_offset="+tzOffset}></iframe>
    </div>
  );
};
export default OITimer;
