
const snakeToCamel = str => str.replace(/_[a-z]/g, letter => `${letter.toUpperCase().replace(/_/,'')}`);

const camelToSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const arrSnakeToCamel = rows => rows.map(row => {
  if(Array.isArray(row)) {
    return arrSnakeToCamel(row);
  }
  if(typeof row === 'object' && row !== null) {
    return objSnakeToCamel(row);
  }
  return row;
});

const objSnakeToCamel = obj => Object
  .keys(obj)
  .reduce((carry, key) => {
    const item = obj[key];

    if(Array.isArray(item)) {
      carry[snakeToCamel(key)] = arrSnakeToCamel(item);
      return carry;
    } else if(typeof item === 'object' && item !== null) {
      carry[snakeToCamel(key)] = objSnakeToCamel(item);
      return carry;
    }

    carry[snakeToCamel(key)] = item;
    return carry;
}, {});

const arrCamelToSnake = rows => rows.map(row => {
  if(Array.isArray(row)) {
    return arrCamelToSnake(row);
  }
  if(typeof row === 'object' && row !== null) {
    return objCamelToSnake(row);
  }
  return row;
});

const objCamelToSnake = obj => Object
  .keys(obj)
  .reduce((carry, key) => {
    const item = obj[key];

    if(Array.isArray(item)) {
      carry[camelToSnake(key)] = arrCamelToSnake(item);
      return carry;
    } else if(typeof item === 'object' && item !== null) {
      carry[camelToSnake(key)] = objCamelToSnake(item);
      return carry;
    }

    carry[camelToSnake(key)] = item;
    return carry;
}, {});


/*
const objProps = {
  camelToSnake: obj => Object
    .keys(obj)
    .reduce((carry, key) => {
      carry[camelToSnake(key)] = obj[key];
      return carry;
    }, {}),
  snakeToCamel: obj => Object
    .keys(obj)
    .reduce((carry, key) => {
      carry[snakeToCamel(key)] = obj[key];
      return carry;
    }, {}),
}
*/

export { camelToSnake, arrCamelToSnake, objCamelToSnake, snakeToCamel, arrSnakeToCamel, objSnakeToCamel };
