import React from "react";
import DeleteStep from "./deleteStep";
import { fieldFinder, editor } from "./fieldHelper";

const Input = ({form, setForm, textType, pathHandle}) => {

  const field = fieldFinder[pathHandle.mode](form, pathHandle);

  const edit = value => {
    editor[pathHandle.mode](value, form, setForm, pathHandle);
  };

  return (
    <div className="process-field">
      <label>{field.label}</label>
      <input type={textType ? textType : "text"} onChange={e => edit(e.currentTarget.value)} value={field.value}/>
      <DeleteStep form={form} setForm={setForm} pathHandle={pathHandle} />
    </div>
  );
};

export default Input;
