import React, { useContext } from "react";
import { GlobalContext } from "../../contexts/global";
import { adminProcessCanRead,
  adminProcessCanEdit,
  adminPwiCanRead,
  adminUserCanRead
} from "../../helpers/permissions";
import { setCookie } from "../../helpers/cookies";
import { NavLink } from "react-router-dom";

const Navigation = () => {

  const global = useContext(GlobalContext);

  if (!(global.token && global.token.length > 0)) {
    return null;
  }

  const logout = () => {
    setCookie('token', '');
    setCookie('app', 'app');
    global.setGlobal({token:'',role:null,app:''});
  };

  return (
    <nav className="visible nav-container">
      {adminPwiCanRead(global.role) && (
      <React.Fragment>
        <NavLink to="/work-order" className="button">Start PWI</NavLink>
        <h3>PWIs</h3>
        <ul>
          <li>
            <NavLink to="/work-order/list/active">Active</NavLink>
          </li>
          <li>
            <NavLink to="/work-order/list/scheduled">Scheduled</NavLink>
          </li>
          <li>
            <NavLink to="/work-order/list/completed">Completed</NavLink>
          </li>
          <li>
            <NavLink to="/work-order/list/aborted">Aborted</NavLink>
          </li>
          <li>
            <NavLink to="/dashboard">All</NavLink>
          </li>
        </ul>
      </React.Fragment>
      )}
      {adminProcessCanRead(global.role) && (
      <React.Fragment>
        <h3>Process</h3>
        <ul>
          {adminProcessCanEdit(global.role) && (
          <li>
            <NavLink to="/pwi/detail">Create</NavLink>
          </li>
          )}
          <li>
            <NavLink to="/pwi/list/published">Published</NavLink>
          </li>
          <li>
            <NavLink to="/pwi/list/pending">Pending</NavLink>
          </li>
          <li>
            <NavLink to="/pwi/list/archived">Archived</NavLink>
          </li>
        </ul>
      </React.Fragment>
      )}
      {adminUserCanRead(global.role) && (
      <React.Fragment>
        <h3>Settings</h3>
        <ul>
          <li>
            <NavLink to="/user/main">
              Manage <span>Users</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings">
              Configure <span>Data</span>
            </NavLink>
          </li>
        </ul>
      </React.Fragment>
      )}
      <button type="button" onClick={ _ => logout() } >Logout</button>
    </nav>
  );
};

export default Navigation;
