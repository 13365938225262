import { Redirect, useParams } from "react-router-dom";
import { createProcess, updateProcess } from "../../../api/Api";
import PwiNav from "./pwiNav";
import SwitchButton from "../../common/switchButton";
import { deepCopy } from "../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../helpers/variableNotation";
import { organicChecks, nonOrganicChecks } from "./schema";
import { isReadOnly } from "./helper";

const PreProduction = ({ form, setForm, role }) => {

  const { id } = useParams();

  const toggleSwitch = (field, value) => {
    const fm = deepCopy(form);
    fm.data.preProduction.steps.forEach((_, k) => {
      fm.data.preProduction.steps[k].options.forEach((opt, ko) => {
        if (opt.slug === field) {
          fm.data.preProduction.steps[k].options[ko].value = value;
        }
      });
    });
    setForm(fm);
  };
  const checkFilter = parseInt(form.data.isOrganic) === 1 ? organicChecks : nonOrganicChecks;
  const steps = checkFilter(form.data.preProduction.steps);
  const nextUrl = id ? '/pwi/' + id + '/production/0' : '/pwi/production/0' ;
  const prevUrl = id ? '/pwi/' + id + '/detail' : '/pwi/detail' ;

  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.saved = true;
    setForm(fm);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };
  const save = async () => {
    const saveToAPI = form.data.id ? updateProcess : createProcess;
    saveToAPI({data:form.data, callbackSuccess, callbackFailure});
  }
  if(form.saved) {
    return <Redirect to="/pwi/list/pending" />;
  }
  return (
    <div>
      <div className="pre-process-steps">
        {steps.map((step, k) => (
          <div className="switches" key={k}>
            <h3>{step.label}</h3>
            {step.options.map((opt, ko) => (
              <div className="switch-flip" key={ko}>
                <label>{opt.label}</label>
                <SwitchButton
                  onChange={(value) => toggleSwitch(opt.slug, value)}
                  checked={opt.value}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <PwiNav next={nextUrl} prev={prevUrl} save={isReadOnly(form.data, role).length > 0 ? null : ()=>save()} />
    </div>
  );
};

export default PreProduction;
