import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { completeWorkOrder } from "../../../../api/Api";
import NumberPadWithToggle from "./numberPadWithToggle";

const CompleteWorkOrder = ({workOrderId}) => {

  const [ redirect, setRedirect ] = useState(false);
  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }
  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  const completeNow = employeeCode => completeWorkOrder({
    id:workOrderId,
    employeeCode,
    callbackFailure,
    callbackSuccess: result => {callbackSuccess(result); setRedirect(true);},
  });

  if(redirect) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <NumberPadWithToggle
      errorMessage={errorMessage}
      onSubmit={completeNow}
      isVisibleNumberPad={isVisibleNumberPad}
      closeNumberPad={closeNumberPad}
      openNumberPad={openNumberPad}
      btnText="Approve"
      btnCssClass="next-step"
    />
  );
};
export default CompleteWorkOrder;
