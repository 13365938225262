const TableRow = ({ data }) => {
  return (
    <tr>
      <td>{data.vessel}</td>
      <td>{data.product_name}</td>
      <td>{data.reference_number}</td>
      <td>{data.schedule_time}</td>
    </tr>
  );
};
export default TableRow;
