import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalContext } from "../../../contexts/global";
import { findProcesses, deleteProcess, archiveProcess, cloneProcess } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import { adminProcessCanRead, adminProcessCanEdit } from "../../../helpers/permissions";
import Paginator from "../../common/paginator";
import PageIndicator from "../../common/pageIndicator";

const pageSettings = {
  pending: {
    title: 'Pending',
    query: `is_archived=0&pending=1`,
  },
  published: {
    title: 'Published',
    query: `is_archived=0&approved=1`,
  },
  archived: {
    title: 'Archived',
    query: `is_archived=1`,
  },
};

const List = () => {
  const defaultPage = 1;
  const defaultNumberPerPage = 20;
  const defaultSearchParams = { p: defaultPage, n: defaultNumberPerPage, size:0, productName:"", bom:"", referenceNumber:""};

  const [ showSearch, setShowSearch ] = useState(false);
  const { query } = useParams();
  const [ searchParams, setSearchParams ] = useState(defaultSearchParams);
  const [ pwis, setPwis ] = useState([]);
  const [ action, setAction ] = useState({id: null, action: null, status: null, errorMessage: ''});

  const onEditSearch = (field, val) => {
    const sParams = deepCopy(searchParams);
    sParams[field] = val;
    setSearchParams(sParams);
  }
  const search = searchArgs => {
    setPwis([]);
    const findPwiSuccess = (result) => {
      setPwis(deepCopy(result.data));
      const sParams = deepCopy(searchArgs);
      sParams.size = parseInt(result.size);
      setSearchParams(sParams);
    };
    const searchQuery = pageSettings[query].query +
      '&n=' + searchArgs['n'] +
      '&p=' + searchArgs['p'] +
      (searchArgs.productName.trim() && '&product_name=' + encodeURIComponent(searchArgs.productName.trim())) +
      (searchArgs.bom.trim() && '&bom=' + encodeURIComponent(searchArgs.bom.trim())) +
      (searchArgs.referenceNumber.trim() && '&reference_number=' + encodeURIComponent(searchArgs.referenceNumber.trim()));
    findProcesses({
      query: searchQuery,
      callbackSuccess: findPwiSuccess,
    });
  };

  useEffect(() => {
    setPwis([]);
    const findPwiSuccess = (result) => {
      setPwis(deepCopy(result.data));
      setSearchParams({
        n: defaultNumberPerPage,
        p: defaultPage,
        size: parseInt(result.size),
        productName: '',
        bom: '',
        referenceNumber: ''
      });
    };
    findProcesses({
      query: pageSettings[query].query + '&n=' + defaultNumberPerPage + '&p=' + defaultPage,
      callbackSuccess: findPwiSuccess,
    });
  }, [query]);

  const confirmAction = (id, action) => {
    setAction({ id, action, status: 'confirm', errorMessage: ''});
  };

  const clickAction = (id, actionFunc) => {
    const act = deepCopy(action);
    act.status = 'loading';
    setAction(act);

    actionFunc({
      id,
      callbackFailure: result => {
        const act = deepCopy(action);
        act.status = 'error';
        act.errorMessage = result.data;
        setAction(act);
      },
      callbackSuccess: _ => findProcesses({
          query: pageSettings[query].query,
          callbackSuccess: (result) => {
            clearAction();
            setPwis(deepCopy(result.data));
        }
      })
    });
  };
  // TODO: merge this code somehow with clickAction
  const clickClone = (id) => {
    const act = deepCopy(action);
    act.status = 'loading';
    setAction(act);

    cloneProcess({
      id,
      callbackFailure: result => {
        const act = deepCopy(action);
        act.status = 'error';
        act.errorMessage = result.data;
        setAction(act);
      },
      callbackSuccess: result =>{
        const act = deepCopy(action);
        act.status = 'redirect';
        // TODO: this is NOT error message, i just haven't had time to define a new property
        act.errorMessage = result.data;
        setAction(act);
      }
    });
  };
  const clearAction = () => {
    setAction({id: null, action: null, status: null, errorMessage:''});
  };

  const modal = {
    clone: {
      title: 'Are you sure you want to clone this Process?',
      error: msg => (<button type="button" onClick={clearAction} className="button-red">ERROR<br/><br/>{msg}</button>),
      loading: <button type="button">Cloning...</button>,
      confirm: <button type="button" onClick={_=>clickClone(action.id)}>Yes, clone</button>,
      redirect: <Link to={`/pwi/${action.errorMessage}/detail`} className="icon-pencil btn-button" title="Edit">View clone</Link>,
    },
    archive: {
      title: 'Are you sure you want to archive this Process?',
      error: msg => (<button type="button" onClick={clearAction} className="button-red">ERROR<br/><br/>{msg}</button>),
      loading: <button type="button">Archiving...</button>,
      confirm: <button type="button" onClick={_=>clickAction(action.id, archiveProcess)}>Yes, archive</button>,
    },
    delete: {
      title: 'Are you sure you want to delete this Process?',
      error: msg => (<button type="button" onClick={clearAction} className="button-red">ERROR<br/><br/>You can not delete processes with existing PWIs.</button>),
      loading: <button type="button">Deleting...</button>,
      confirm: <button type="button" onClick={_=>clickAction(action.id, deleteProcess)}>Yes, delete</button>,
    }
  };

  const cols = [
    { 'key': 'product_name', 'label': 'Product', 'fields': [
      {
        'key': 'version_note', 'label': 'Version Note', 'filter': row=>true
      },
      {
        'key': 'archived', 'label': 'Archived On', 'filter': row=>{
        const d = new Date(row.archived.replace(/\s.+/, ''));
        const nullD = new Date('1970-01-01');
        return d > nullD;
        }
      }
    ]},
    { 'key': 'bom', 'label': 'BOM' },
    { 'key': 'reference_number', 'label': 'Ref #' },
    { 'key': 'version_number', 'label': 'Vs. #' },
  ];

  const global = useContext(GlobalContext);

  if (!(global.token && global.token.length > 0)) {
    return null;
  }

  return (
    <div className="table">
      <h2>{pageSettings[query].title} Processes</h2>
      <button type="button" className="btn-show-search" onClick={()=>setShowSearch(!showSearch)}>
        <span className={"icon-" + (showSearch ? "down-open" : "right-open")}></span>
        <span>Search</span>
      </button>
      {showSearch && (
      <div className="ctrl-search pwi-search">
        <label>Product Name</label>
        <input type="text" value={searchParams.productName} onChange={e=>onEditSearch('productName',e.currentTarget.value)} />
        <label>BOM</label>
        <input type="text" value={searchParams.bom} onChange={e=>onEditSearch('bom',e.currentTarget.value)} />
        <label>Ref</label>
        <input type="text" value={searchParams.referenceNumber} onChange={e=>onEditSearch('referenceNumber',e.currentTarget.value)} />
        <button type="button" className="btn-search" onClick={()=>search({
            n: defaultNumberPerPage,
            p: defaultPage,
            size: 0,
            productName: searchParams.productName,
            bom: searchParams.bom,
            referenceNumber: searchParams.referenceNumber,
          })
        }>
          <span className="icon-search"></span>
          <span>Search</span>
        </button>
      </div>
      )}
      <PageIndicator currentPage={searchParams.p} currentNumberPerPage={searchParams.n} resultSize={searchParams.size} />
      <table>
        <thead>
          <tr>
            {cols.map(item => <td key={item.key}>{item.label}</td>)}
          </tr>
        </thead>
        <tbody>
          {pwis.length > 0 ? pwis.map((row, k) => (
            <tr key={k}>
              {cols.map(item => (
                  <td key={item.key}>
                    <span className="field-main">{row[item.key]}</span>
                    {"fields" in item && item.fields
                      .filter(subfield=> row[subfield.key] && subfield.filter(row))
                      .map(subfield => (
                      <span className="field-sub" key={subfield.key}>
                        <strong className="field-sub-label">{subfield.label}</strong>
                        <span className="field-sub-value">{row[subfield.key]}</span>
                      </span>
                    ))}
                  </td>
                )
              )}
              {adminProcessCanEdit(global.role) && (
              <td className="action">
                <button className="icon-docs btn-copy" type="button" title="Clone" onClick={_ => confirmAction(row.id, 'clone')}><span className="tip">Create new version</span></button>
                <Link to={`/pwi/${row.id}/detail`} className="icon-pencil" title="Edit"><span className="tip">Edit</span></Link>
                {['pending', 'published'].includes(query) && <button className={"icon-trash" + (row.used_by_work_order ? ' disabled' : '')} type="button" onClick={_ => row.used_by_work_order ? alert('NOTE: Cannot delete a process that is in use by a PWI.') : confirmAction(row.id, 'delete')} title="Delete"><span className="tip">Delete</span></button>}
                {['pending', 'published'].includes(query) && <button className="icon-file-archive" type="button" onClick={_ => confirmAction(row.id, 'archive')} title="Archive"><span className="tip">Archive</span></button>}
              </td>
              )}
              {adminProcessCanRead(global.role) && !adminProcessCanEdit(global.role) && (
                <td>
                  <Link to={`/pwi/${row.id}/detail`} className="btn-action button" title="View">View</Link>
                </td>
              )}
            </tr>
          )) :
            (<tr><td colSpan={cols.length}> - </td></tr>)
          }
        </tbody>
      </table>
      <Paginator
        currentPage={searchParams.p}
        currentNumberPerPage={searchParams.n}
        resultSize={searchParams.size}
        next={()=>{const sParams = deepCopy(searchParams); sParams.p = sParams.p + 1; search(sParams);}}
        previous={()=>{const sParams = deepCopy(searchParams); sParams.p = sParams.p - 1; search(sParams);}}
      />
      {action.action && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" type="button" onClick={clearAction}>&#10006;</button>
            <h3 className="modal-title">{modal[action.action].title}</h3>
            <div className="modal-controls">
            {action.status === 'error' && modal[action.action].error(action.errorMessage) }
            {action.status === 'loading' && modal[action.action].loading}
            {action.status === 'confirm' && modal[action.action].confirm}
            {action.status === 'redirect' && modal[action.action].redirect}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default List;
