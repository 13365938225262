import { useState } from "react";
import { beginProcess } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import NumberPadWithToggle from "./numberPadWithToggle";

const ActionBeginProcess = ({processId, form, setForm, readOnly}) => {

  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }
  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  const beginNow = (employeeCode) => {
    beginProcess({id: processId,
      employeeCode,
      callbackFailure,
      callbackSuccess: result => {
        const fm = deepCopy(form);
        for(let x in fm.data.processes) {
          if(fm.data.processes[x].id === processId) {
            fm.data.processes[x].startTime = result.process.start_time;
          }
        }
        callbackSuccess();
        setForm(fm);
      }
    });
  }

  if(readOnly) {
    return null;
  }
  return <NumberPadWithToggle
          errorMessage={errorMessage}
          isVisibleNumberPad={isVisibleNumberPad}
          closeNumberPad={closeNumberPad}
          openNumberPad={openNumberPad}
          onSubmit={beginNow}
          btnText="Start Process"
  />;
};
export default ActionBeginProcess;
