import React from "react";
import DeleteStep from "./deleteStep";
import { fieldFinder, editor } from "./fieldHelper";

const DropDown = ({form, setForm, options, pathHandle}) => {

  const field = fieldFinder[pathHandle.mode](form, pathHandle);

  const edit = value => {
    editor[pathHandle.mode](value, form, setForm, pathHandle);
  };

  return (
    <div className="process-field">
      <label>{field.label}</label>
      <select onChange={e => edit(e.currentTarget.value)} value={field.value}>
        <option>-- Select --</option>
        {options.map(opt => <option key={opt.slug} value={opt.slug}>{opt.label}</option>)}
      </select>
      <DeleteStep form={form} setForm={setForm} pathHandle={pathHandle} />
    </div>
  );
};

export default DropDown;
