
const steps = [
  {
    label: "Caution",
    slug: "caution",
    type: {admin: "caution", terminal: "caution"},
    options: [], // Load this from your form
    config: { admin: ["fixed"], terminal: []},
    value: "",
  },
  {
    label: "PPE",
    slug: "ppe",
    type: {admin: "ppe", terminal: "ppe"},
    options: [], // Load this from your form
    config: { admin: ["fixed"], terminal: []},
    // Default values
    value: ["gloves", "coveralls", "safety-shoes", "safety-glasses"],
  },
  {
    label: "Addition Rate (Auto)",
    slug: "autoAdditionRate",
    type: {admin: "boolean", terminal: "autoAdditionRate"},
    config: { admin: ["fixed"], terminal: ["requires-employee-code", "requires-complete"]},
    qty: 1,
    time: 1,
    value: false,
    inputs: [
      {
        slug: "autoQuantityCharged",
        type: {admin: "none", terminal: "number"},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "Quantity Charged:"},
        value: 0,
      }
    ],
  },
  {
    label: "Addition Rate (Manual)",
    slug: "manualAdditionRate",
    type: {admin: "manualAdditionRate", terminal: "manualAdditionRate"},
    config: { admin: ["fixed"], terminal: ["requires-employee-code", "requires-complete"]},
    qty: 1,
    time: 1,
    value: false,
    inputs: [
      {
        slug: "manualBags",
        type: {admin: "none", terminal: "number"},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "Total Bags/Pails:"},
        value: 0,
      },
      {
        slug: "manualQuantityCharged",
        type: {admin: "none", terminal: "number"},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "Quantity Charged:"},
        value: 0,
      }
    ],
  },
  {
    label: "Timed Instructions",
    slug: "mixingInstructions",
    type: {admin: "mixing", terminal: "mixing"},
    config: { admin: [], terminal: ["requires-employee-code", "requires-complete"]},
    options: [], // Load this from your form
    inputs: [
      {
        label: {admin: "", terminal: "Timer"},
        slug: "instructions",
        config: {admin:[], terminal:["required"]},
        type: {admin: "none", terminal: "timer"},
        value: "",
      }
    ],

    value: "",
  },
  {
    label: "Heating Required",
    slug: "requiredHeating",
    type: {admin: "boolean", terminal: "boolean"},
    config: {admin:[], terminal:["requires-employee-code"]},
    value: false,
    inputs: [
      {
        slug: "tempHeat",
        type: {admin: "number", terminal: "none"},
        // label.admin is actually used in the terminal!!!
        label: {admin: "Temp. Req.:", terminal: "Actual:"},
        value: 0,
      }
    ],
  },
  {
    label: "Cooling Required",
    slug: "requiredCooling",
    type: {admin: "boolean", terminal: "boolean"},
    config: {admin:[], terminal:["requires-employee-code"]},
    value: false,
    inputs: [
      {
        slug: "tempCool",
        type: {admin: "number", terminal: "none"},
        // label.admin is actually used in the terminal!!!
        label: {admin: "Temp. Req.:", terminal: "Actual:"},
        value: 0,
      }
    ],
  },
  {
    label: "Supervisor Approval Required",
    slug: "supervisorApproval",
    type: {admin: "boolean", terminal: "supervisorApproval"},
    value: false,
  },
  {
    label: "Check pH",
    slug: "checkPh",
    type: {admin: "boolean", terminal: "boolean"},
    config: {admin:[], terminal:["requires-employee-code", "requires-complete"]},
    value: false,
    inputs: [
      {
        slug: "phLevel",
        type: {admin: "text", terminal: "number"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "ph Level:", terminal: "Actual:"},
        value: "",
      },
      {
        slug: "phLevelAdded",
        type: {admin: "none", terminal: "textarea"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "What was added?"},
        value: "",
      }
    ],
  },
  {
    label: "Check SG",
    slug: "checkSG",
    type: {admin: "boolean", terminal: "boolean"},
    config: {admin:[], terminal:["requires-employee-code", "requires-complete"]},
    value: false,
    inputs: [
      {
        slug: "sgLevel",
        type: {admin: "text", terminal: "number"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "SG Level:", terminal: "Actual:"},
        value: "",
      },
      {
        slug: "sgLevelAdded",
        type: {admin: "none", terminal: "textarea"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "What was added?"},
        value: "",
      }
    ],
  },

  {
    label: "Check Drum For Solids",
    slug: "checkDrum",
    type: {admin: "boolean", terminal: "boolean"},
    config: {admin:[], terminal:["requires-employee-code"]},
    value: false,
  },
  {
    label: "Take Samples",
    slug: "takeSamples",
    type: {admin: "boolean", terminal: "boolean"},
    value: false,
  },
/*
  {
    label: "Retention Sample",
    slug: "retentionSample",
    type: {admin: "boolean", terminal: "boolean"},
    value: false,
  },
*/
  {
    label: "Additional Instructions",
    slug: "additionalFilter",
    type: {admin: "boolean", terminal: "boolean"},
    value: false,
    inputs: [
      {
        label: {admin: "Instructions:", terminal: "Actual:"},
        slug: "instructions",
        type: {admin: "text", terminal: "read-only"},
        value: "",
      }
    ],
  },
  {
    label: "Batch/SG/PH",
    slug: "batchSGPH",
    type: {admin: "boolean", terminal: "boolean"},
    config: {admin:[], terminal:["requires-employee-code", "requires-complete"]},
    value: false,
    inputs: [
      {
        slug: "batch",
        type: {admin: "none", terminal: "number"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "Batch:"},
        value: "",
      },
      {
        slug: "sg",
        type: {admin: "none", terminal: "number"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "SG:"},
        value: "",
      },
      {
        slug: "ph",
        type: {admin: "none", terminal: "number"},
        config: {admin:[], terminal:["required"]},
        // label.admin is actually used in the terminal!!!
        label: {admin: "", terminal: "PH:"},
        value: "",
      }
    ],
  }
];
export default steps;
