import React from "react";
import DeleteStep from "./deleteStep";
import { fieldFinder } from "./fieldHelper";

const Label = ({form, setForm, textType, pathHandle}) => {

  const field = fieldFinder[pathHandle.mode](form, pathHandle);

  return (
    <div className="process-field">
      <label>{field.label}</label>
      <span>{field.value}</span>
      <DeleteStep form={form} setForm={setForm} pathHandle={pathHandle} />
    </div>
  );
};

export default Label;
