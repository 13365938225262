import React from "react";
import NumberPad from "./numberPad";

const NumberPadWithToggle = ({btnText, btnCssClass, onSubmit, errorMessage, isVisibleNumberPad, openNumberPad, closeNumberPad}) => {

  if(isVisibleNumberPad) {
    return (
      <NumberPad
        onSubmit={onSubmit}
        errorMessage={errorMessage}
        closeNumberPad={closeNumberPad}
      />
    );
  }

  return <button className={btnCssClass} onClick={openNumberPad}>{btnText}</button>;
};
export default NumberPadWithToggle;
