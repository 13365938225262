import { Link, useParams } from "react-router-dom";

const CreateNav = () => {

  const { id } = useParams();

  const idUrl = id && !isNaN(id) ? id+"/" : "";

  return (
    <div>
      <ul className="create-nav">
        <li>
          <h2>
            Create <span>Process</span>
          </h2>
        </li>
        <li>
          <Link to={`/pwi/${idUrl}detail`}> Product Details </Link>
        </li>
        <li>
          <Link to={`/pwi/${idUrl}pre-production`}> Pre-Process </Link>
        </li>
        <li>
          <Link to={`/pwi/${idUrl}production/0`}> Production </Link>
        </li>
        <li>
          <Link to={`/pwi/${idUrl}post-production`}> Post Production </Link>
        </li>
      </ul>
    </div>
  );
};

export default CreateNav;
