
const schema = {
   "productKey":"",
   "productName":"",
   "isOrganic":0,
   "bom":"",
   "referenceNumber":"",
   "filter":"",
   "preProduction":{
      "steps":[
         {
            "label":"Rinsing",
            "slug":"rinsing",
            "config": {"admin":[], "terminal":["requires-employee-code"]},
            "options":[
               {
                  "slug":"rinseHoses",
                  "label":"Rinse Hoses",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  },
                  "isOrganic":true
               },
               {
                  "slug":"rinsePump",
                  "label":"Rinse Pump",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                        
                     ],
                     "terminal":[
                        
                     ]
                  },
               },
               {
                  "slug":"rinseTank",
                  "label":"Rinse Tank",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"rinseFilter",
                  "label":"Rinse Filter",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               }
            ]
         },
         {
            "label":"Check",
            "slug":"check",
            "config": {"admin":[], "terminal":["requires-employee-code"]},
            "options":[
               {
                  "slug":"inspectForeign",
                  "label":"Inspect Tank for Forgein Objects",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"inspectScreens",
                  "label":"Inspect Tank for Screens, Roof, Mixer",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"inspectPump",
                  "label":"Inspect Pump",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"hosesInletOutlet",
                  "label":"Hoses - Inlet/Outlet",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"samplingPort",
                  "label":"Sampling Port",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
            ]
         },
         {
            "label":"Other",
            "slug":"other",
            "options":[
/*
               {
                  "slug":"allSteps",
                  "label":"All steps can be completed on this shift",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                       "switch-yes-no", 
                     ]
                  }

               },
*/
               {
                  "slug":"otherTimingRelated",
                  "label":"There are other timing related requirements",
                  "value":false,
                  "type":{
                     "admin":"boolean",
                     "terminal":"text-if-true"
                  }
               }
            ]
         },
         {
            "label":"Temperature",
            "slug":"temperature",
            "config": {"admin":[], "terminal":["ignore"]},
            "options":[
               {
                  "slug":"heatRequired",
                  "label":"Heat Required",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"text-if-true"
                  }
               },
               {
                  "slug":"coolingRequired",
                  "label":"Cooling Required",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"text-if-true"
                  }
               },
               {
                  "slug":"exothermic",
                  "label":"Exothermic",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"text-if-true"
                  }
               },
               {
                  "slug":"endothermic",
                  "label":"Endothermic",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"text-if-true"
                  }
               }
            ]
         },
         {
            "label":"Materials Inspection",
            "slug":"materialsInspection",
            "config": {"admin":[], "terminal":["requires-employee-code"]},
            "options":[
               {
                  "slug":"checkItem",
                  "label":"Check Item #",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"checkManufacturer",
                  "label":"Check Manufacturer",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               },
               {
                  "slug":"quantityMatches",
                  "label":"Check Quantity Matches Work Order",
                  "value":true,
                  "type":{
                     "admin":"boolean",
                     "terminal":"boolean"
                  },
                  "config":{
                     "admin":[
                       "organic",
                     ],
                     "terminal":[
                        
                     ]
                  }
               }
            ]
         }
      ]
   },
   "production":{
      "processes":[
         
      ]
   },
   "postProduction":{
      "qualityControl":[
         {
            "slug":"sg",
            "label":"SG",
            "type":{
               "admin":"text",
               "terminal":"number"
            },
            "value":""
         },
         {
            "slug":"ph",
            "label":"PH",
            "type":{
               "admin":"text",
               "terminal":"number"
            },
            "value":""
         },
         {
            "slug":"colour",
            "label":"Colour Check",
            "type":{
               "admin":"text",
               "terminal":"text"
            },
            "value":""
         },
         {
            "slug":"clarity",
            "label":"Clarity",
            "type":{
               "admin":"text",
               "terminal":"text"
            },
            "value":""
         },
         {
            "slug":"quantityYield",
            "label":"Quantity Yield Kg",
            "type":{
               "admin":"label",
               "terminal":"quantityYield"
            },
            "value":""
         },
/*
         {
            "slug":"quantityYieldPct",
            "label":"Quantity Yield %",
            "type":{
               "admin":"label",
               "terminal":"quantityYieldPct"
            },
            "value":""
         },
*/
      ]
   }
};
export const organicChecks = (checks) => checks;
export const nonOrganicChecks = (checks) => {
  return checks
  .map(sect => {
    return {
      slug: sect.slug,
      label: sect.label,
      options: sect
        .options
        .filter(opt => !(opt.config && opt.config.admin && opt.config.admin.includes('organic')))
    };
  })
  .filter(sect => sect.options.length > 0);
};
export default schema;
