import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useParams } from "react-router-dom";

import Detail from "./detail";
import PreProduction from "./preProduction";
import Production from "./production";
import PostProduction from "./postProduction";
import CreateNavigation from "./processNav";
import schema from "./schema";
import { isReadOnly } from "./helper";
import { deepCopy } from "../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../helpers/variableNotation";
import { GlobalContext } from "../../../contexts/global";
import { getProcess, findProducts, getDictionary } from "../../../api/Api";

const Create = () => {
  const { id } = useParams();

  const [form, setForm] = useState({
    products: [],
    saved: false,
    error: {},
    data: schema,
  });

  useEffect(() => {
    const findPwiSuccess = (result) => {
      setForm((currentForm) => {
        const fm = deepCopy(currentForm);
        fm.data = objSnakeToCamel(result.data);
        return fm;
      });
    };

    if (!isNaN(id)) {
      getProcess({
        id,
        callbackSuccess: findPwiSuccess,
      });
    } else {
      // A new PWI requires a copy of the products and the dictionary terms for ppe and caution
      // These dictionary terms will be "saved" against the pwi and used going forward for this pwi.
      // This way if people change the Configure Data it will not have historical effects
      const findProductsSuccess = (result) => {
        setForm((currentForm) => {
          const fm = deepCopy(currentForm);
          fm.products = result.data.map(row=>objSnakeToCamel(row));
          return fm;
        });
      };
      findProducts({
        query:'process=0',
        callbackSuccess: findProductsSuccess,
      });

      const getDictionarySuccess = (result) => {
        setForm((currentForm) => {
          const fm = deepCopy(currentForm);
          fm.data.dictionary = {
            ppe: result.data.ppe,
            caution: result.data.caution,
            mixing: result.data.mixing
          };
          return fm;
        });
      };
      getDictionary({
        callbackSuccess: getDictionarySuccess,
      });
    }
  }, [id]);

  const global = useContext(GlobalContext);

  if (!(global.token && global.token.length > 0)) {
    return null;
  }
  const readOnlyReasons = isReadOnly(form.data, global.role);
  return (
    <div className="form-page">
      <CreateNavigation />
      {readOnlyReasons.length > 0 && (
        <div className="process-in-use">
          <p><strong>READ ONLY</strong></p>
          <p>You can not edit this process for the following reasons:</p>
          <ol>
          {readOnlyReasons.includes("archived") && (<li>It is archived.</li>)}
          {readOnlyReasons.includes("used-by-work-order") && (<li>It is used by a PWI.</li>)}
          {readOnlyReasons.includes("not-authorized") && (<li>You do not have sufficient privileges.</li>)}
          </ol>
        </div>
      )}
      <Switch>
        <Route path="/pwi/:id?/detail">
          <Detail form={form} setForm={setForm} role={global.role} />
        </Route>
        <Route path="/pwi/:id?/pre-production">
          <PreProduction form={form} setForm={setForm} role={global.role} />
        </Route>
        <Route path="/pwi/:id?/production/:processNumber">
          <Production form={form} setForm={setForm} role={global.role} />
        </Route>
        <Route path="/pwi/:id?/post-production">
          <PostProduction form={form} setForm={setForm} role={global.role} />
        </Route>
      </Switch>
    </div>
  );
};

export default Create;
