import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { listVesselStatus } from '../../../api/Api';
import { arrSnakeToCamel } from '../../../helpers/variableNotation';
import vessels from '../../../config/vessels';
import NutriagLogo from "../../common/logo.svg";

const parseWorkOrder = (vesselSlug, vesselStatusList) => {
  const stat = vesselStatusList.filter(ves => ves.vessel === vesselSlug);
  if(stat.length < 1) {
    return false;
  }
  const [ currentVessel ] = stat;
  if(currentVessel.activateTime && !currentVessel.endTime && !currentVessel.cancelTime) { // && postprod not approve
    return currentVessel;
  }
  return null;
};

const loadInterval = 5000;
let mounted = true;
const refreshData = setVesselStatusList => {
  setTimeout(()=>{
    listVesselStatus({callbackSuccess: result => {
        if(mounted) {
          setVesselStatusList(arrSnakeToCamel(result.data));
          refreshData(setVesselStatusList);
        }
      }
    })
  },loadInterval);
};

const Vessels = () => {
  const [ vesselStatusList, setVesselStatusList ] = useState([]);

  useEffect(() =>{
    mounted = true;
    listVesselStatus({
      callbackSuccess: result => {
        setVesselStatusList(arrSnakeToCamel(result.data));
        refreshData(setVesselStatusList);
      }
    });
    return ()=>{
      mounted = false;
    };
  },[]);

  return (
    <div className="vessel-view">
      <div className="heading-logo">
        <img src={NutriagLogo} alt="nutriag" />
      </div>
      <div className="vessel-container">
        {vessels.map(vessel => {
          const workOrder = parseWorkOrder(vessel.slug, vesselStatusList);
          const productName = workOrder && workOrder.pwi ? workOrder.pwi.productName : null;
          return workOrder ? (
            <Link className="vessel-item" key={vessel.slug} to={`/vessel/${workOrder.id}`}>
              <span className="vessel-label">Vessel</span>
              <span className="vessel-product-name">{productName}</span>
              <span className="vessel-number">{vessel.text}</span>
            </Link>
            ) : (
            <div className="vessel-item" key={vessel.slug}>
              <span className="vessel-label">Vessel</span>
              <span className="vessel-number">{vessel.text}</span>
            </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default Vessels;
