import React from "react";
import { updatePwiProcess } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import SwitchButton from "../../../common/switchButton";

const RetentionSample = ({processId, form, setForm, readOnly}) => {

  const process = form.data.processes.find(proc => proc.id === processId);

  const updatePwiProcessNow = val => {
    const retentionSample = val ? 1 : 0;
    const fm = deepCopy(form);
    for(let x=0; x < fm.data.processes.length; x++) {
      if(fm.data.processes[x].id === processId) {
        fm.data.processes[x].retentionSample = retentionSample;
      }
    }
    setForm(fm);

    updatePwiProcess({id: processId,
      data: {retentionSample},
      callbackFailure: result => console.log('save error', result),
      callbackSuccess: result => {}
    });
  };

  return (
    <div className="vessel-step">
      <div className="vessel-options">
        <div className="vessel-option">
          <label>Retention Sample</label>
          <SwitchButton
            labels={{yes: (<span className="complete">Complete</span>), no: (<span className="not-complete">&nbsp;</span>)}}
            onChange={val => updatePwiProcessNow(val)}
            readOnly={readOnly}
            checked={parseInt(process.retentionSample) === 1}
          />
        </div>
      </div>
    </div>
  );
};
export default RetentionSample;
