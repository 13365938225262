import React from "react";
import SwitchButton from "../../../common/switchButton";
import DeleteStep from "./deleteStep";
import { deepCopy } from "../../../../helpers/deepCopy";
import { fieldFinder, editor } from "./fieldHelper";

const editorAddition = {
  production: (additionalType, value, form, setForm, pathHandle) => {
    const fm = deepCopy(form);
    fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex][additionalType] = value;
    setForm(fm);
  },

};

const AdditionRateStep = ({form, setForm, pathHandle}) => {

  const field = fieldFinder[pathHandle.mode](form, pathHandle);

  const edit = value => {
    editor[pathHandle.mode](value, form, setForm, pathHandle);
  };

  const editAddition = (additionalType, value) => {
    editorAddition[pathHandle.mode](additionalType, value, form, setForm, pathHandle);
  };

  return (
    <div className="process-field addition-rate-manual">
      <label>{field.label}</label>
      <SwitchButton onChange={value => edit(value)} checked={field.value} />
      {field.value && (
        <div>
          <select onChange={e => editAddition("time", e.currentTarget.value)} value={field.time}>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>Low</option>
            <option>Medium</option>
            <option>High</option>
          </select>
          <span> mins / </span>
          <select onChange={e => editAddition("qty", e.currentTarget.value)} value={field.qty}>
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <span> bags or pails </span>
        </div>
      )}
      <DeleteStep form={form} setForm={setForm} pathHandle={pathHandle} />
    </div>
  );
};

export default AdditionRateStep;
