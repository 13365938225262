import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// NOTE: John Lai just copied this code here:: https://codesandbox.io/s/k260nyxq9v?file=/index.js
// Then converted it to functional component instead of class component
// Hence, John doesn't actually know how this code works yet.  Be careful!

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const App = ({items, setItems, idHandle, renderContent, renderActions}) => {

  const draggableId = idHandle ?? 'id';
  const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const sortedItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      setItems(sortedItems);
  };
  // NOTE: this might become a separate draggable section in the future
  const notDraggables = items.map((item, index) => item.config
    && item.config.admin
    && item.config.admin.includes('fixed')
    ? <div key={item[draggableId]} className="not-sortable-item">{renderContent(item, index)}</div>
    : null
  );
  return (
    <React.Fragment>
      {notDraggables.filter(item => item !== null).length > 0 && <div className="not-sortable">{notDraggables}</div>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="sortable-area"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => item.config
                && item.config.admin
                && item.config.admin.includes('fixed')
                ? null
                : (
                <Draggable key={item[draggableId]} draggableId={item[draggableId]} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="sortable-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderContent(item, index)}
                      {renderActions && renderActions(item, index)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
};

export default App;
