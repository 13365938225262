import React from "react";
import { updatePwiProcess } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";

const Notes = ({processId, form, setForm, readOnly}) => {

  const process = form.data.processes.find(proc => proc.id === processId);

  const updatePwiProcessNow = notes => {

    const fm = deepCopy(form);
    for(let x=0; x < fm.data.processes.length; x++) {
      if(fm.data.processes[x].id === processId) {
        fm.data.processes[x].notes = notes;
      }
    }
    setForm(fm);

    updatePwiProcess({id: processId,
      data: {notes},
      callbackFailure: result => console.log('save error', result),
      callbackSuccess: result => {}
    });
  };

  return (
    <div className="vessel-step notes">
      <h3 htmlFor="notes">Notes:</h3>
      <textarea id="notes" onChange={evt => updatePwiProcessNow(evt.currentTarget.value)} readOnly={readOnly} value={process.notes}></textarea>
    </div>
  );
};
export default Notes;
