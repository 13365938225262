const vessels = [
  {'text':'R1', 'slug':'r1'},
  {'text':'R2', 'slug':'r2'},
  {'text':'R3A', 'slug':'r3a'},
  {'text':'R3B', 'slug':'r3b'},
  {'text':'R4', 'slug':'r4'},
  {'text':'R5', 'slug':'r5'},
  {'text':'R6', 'slug':'r6'},
  {'text':'R7', 'slug':'r7'},
  {'text':'M1', 'slug':'m1'},
  {'text':'M2', 'slug':'m2'},
  {'text':'PM (Premixer)', 'slug':'pm'},
  {'text':'LR (Lab reactor)', 'slug':'lr'}
];
export default vessels;
