import React, { useState } from "react";

import { deepCopy } from "../../../helpers/deepCopy";

const AddQualityControl = ({addLocation, form, setForm}) => {

  const [ showAddQualityControl, setShowAddQualityControl ] = useState('');
  const [ fieldName, setFieldName ] = useState('');
  const [ fieldType, setFieldType ] = useState('number');

  const addField = () => {
    const fm = deepCopy(form);
    const newQualityControl = {
      label: fieldName,
      slug: fieldName.toLowerCase().replace(/[^0-9a-z]/,''),
      type: {
        admin: fieldType,
        terminal: fieldType,
      },
      value: '',
    };
    newQualityControl.slug += (new Date()).getTime();
    if(showAddQualityControl === 'end') {
      fm.data.postProduction.qualityControl.push(newQualityControl);
    } else {
      fm.data.postProduction.qualityControl.unshift(newQualityControl);
    }
    setForm(fm);
    setShowAddQualityControl('');
  };
  return (
    <React.Fragment>
      {showAddQualityControl && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" type="button" onClick={_=>setShowAddQualityControl('')}>&times;</button>
            <h3>Add new Quality Control:</h3>
            <div>
              <label>Field Name</label>
              <input type="text" onChange={e => setFieldName(e.currentTarget.value)} value={fieldName} />
            </div>
            <div>
              <label>Field Type</label>
              <select onChange={e => setFieldType(e.currentTarget.value)} value={fieldType}>
                <option value="number">Number</option>
                <option value="text">Text</option>
              </select>
            </div>
            <div className="modal-controls">
              <button type="button" onClick={_=>addField()}>Add</button>
            </div>
          </div>
        </div>
      )}
      <button type="button" className="add-button" onClick={_=>setShowAddQualityControl(addLocation)}>Add Quality Control Field</button>
    </React.Fragment>
  );
};

export default AddQualityControl;
