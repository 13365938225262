import { Switch, Route } from "react-router-dom";

import List from "./list";
import Edit from "./edit";

const User = () => {
  return (
    <div>
      <Switch>
        <Route path="/user/main">
          <List />
        </Route>
        <Route path="/user/:id?">
          <Edit />
        </Route>
      </Switch>
    </div>
  );
};

export default User;
