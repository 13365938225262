import { useState } from "react";
import { Link } from 'react-router-dom';
import { reset } from "../../api/Api";
import { deepCopy } from "../../helpers/deepCopy";
import { _e } from "../../helpers/validation";
import { objSnakeToCamel } from "../../helpers/variableNotation";

import Footer from "../../components/footer/footer";

const Resetpage = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirm: "",
    error: {},
    sent: false,
  });
  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.sent = true;
    setForm(fm);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };

  const edit = (fieldName, val) => {
    const fm = deepCopy(form);
    fm[fieldName] = val;
    setForm(fm);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  return (
    <div className="signuppage">
      <form
        className="signup-form"
        onSubmit={(e) => {
          e.preventDefault();
          reset({ email: form.email , password: form.password, token, callbackSuccess, callbackFailure });
        }}
      >
        <div className="form-head">
          <h1 className="head-text">Choose a New Password</h1>
        </div>
        <div className="form-body">
          {form.sent
            ? (
          <div className="container">
            <p className="register-confirm">Success!  <Link to="login">Click here to login</Link></p>
          </div>
            )
            : (
          <div className="container">
            {Object.keys(form.error).length > 0 && (
              <p className="error-message">Review form below for errors.</p>
            )}
            <label className="input-label" id="emailLabel" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              className="input-value"
              type="text"
              placeholder="Email"
              value={form.email}
              onChange={(e) => edit("email", e.currentTarget.value)}
            />
            {_e(form.error.email)}
            <label
              className="input-label"
              id="passwordLabel"
              htmlFor="password"
            >
              Password
            </label>
            <input
              id="password"
              className="input-value"
              type="password"
              placeholder="Password"
              value={form.password}
              onChange={(e) => edit("password", e.currentTarget.value)}
            />
            {_e(form.error.password)}
            <label
              className="input-label"
              id="password2Label"
              htmlFor="password2"
            >
              Confirm Password
            </label>
            <input
              id="password2"
              className="input-value"
              type="password"
              placeholder="Confirm password"
              value={form.confirm}
              onChange={(e) => edit("confirm", e.currentTarget.value)}
            />
            {form.confirm && form.confirm !== form.password && <span className="error-message">Passwords do not match</span>}

            <div className="form-buttons">
              <button className="signup-button btn" type="submit">
                Reset Password
              </button>
            </div>
          </div>)}
        </div>
      </form>
      <section className="footer-section">
        <Footer />
      </section>
    </div>
  );
};

export default Resetpage;
