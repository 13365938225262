// TODO: work order means pwi and pwi means process --- still need to full update the variable names on the react side

const adminCanRead = role => [1,2,3].includes(role);

const adminPwiCanRead = role => [2,3].includes(role);

const adminPwiCanEdit = role => [2,3].includes(role);

const adminProcessCanEdit = role => [3].includes(role);

const adminProcessCanRead = role => [1,2,3].includes(role);

const adminProcessCanPublish = role => [3].includes(role);

const adminUserCanRead = role => [3].includes(role);


export {
  adminCanRead,
  adminPwiCanRead,
  adminPwiCanEdit,
  adminProcessCanRead,
  adminProcessCanEdit,
  adminProcessCanPublish,
  adminUserCanRead
};
