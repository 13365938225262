import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { register, findUsers, updateUser } from "../../api/Api";
import { deepCopy } from "../../helpers/deepCopy";
import { _e } from "../../helpers/validation";
import { objSnakeToCamel } from "../../helpers/variableNotation";

const RegisterPage = () => {
  const { id } = useParams();
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirm: "",
    firstName: "",
    lastName: "",
    role: "0",
    employeeCode: "",
    error: {},
    success: false,
  });

  useEffect(()=>{
    if(id) {
      findUsers({
        query: `id=${id}`,
        callbackSuccess: result => {
          const fm = objSnakeToCamel(deepCopy(result.data));
          fm.error = {};
          fm.success = false;
          fm.password = "";
          fm.confirm = "";
          setForm(fm);
        }
      });
    }
  },[id]);
  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.success = true;
    setForm(fm);
    window.scroll(0,0);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };

  const edit = (fieldName, val) => {
    const fm = deepCopy(form);
    fm[fieldName] = val;
    setForm(fm);
  };

  const saveUser = (payload) => {
    if(!payload.data.password) {
      delete payload.data.password;
    }
    updateUser(payload);
  };
  const submitForm = form.id ? saveUser : register;
  const roleOptions = [
    {value:"0", text:"Technician"},
    {value:"1", text:"Supervisor"},
    {value:"2", text:"Manager"},
    {value:"3", text:"Admin"}
  ];

  return (
      <form
        id="user-detail"
        className="form-page"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm({ data: { ...form }, callbackSuccess, callbackFailure });
        }}
      >
        <h1 className="head-text">{id ? 'Edit User' : 'New User'}</h1>
          {form.success
            ? (
          <div className="container">
            <p className="register-confirm">Success!</p>
          </div>
            )
            : (
          <React.Fragment>
            {Object.keys(form.error).length > 0 && (
              <p className="error-message">Review form below for errors.</p>
            )}
            <div className="form-field">
              <label className="input-label" id="fNameLabel" htmlFor="fName">
                First Name
              </label>
              <input
                id="fName"
                className="input-value"
                type="text"
                placeholder="First Name"
                value={form.firstName}
                onChange={(e) => edit("firstName", e.currentTarget.value)}
              />
              {_e(form.error.firstName)}
            </div>
            <div className="form-field">
              <label className="input-label" id="lNameLabel" htmlFor="lName">
                Last Name
              </label>
              <input
                id="lName"
                className="input-value"
                type="text"
                placeholder="Last Name"
                value={form.lastName}
                onChange={(e) => edit("lastName", e.currentTarget.value)}
              />
              {_e(form.error.lastName)}
            </div>
            <div className="form-field">
              <label className="input-label" id="roleLabel" htmlFor="role">
                Role
              </label>
              <select
                id="role"
                className="input-value"
                placeholder="Role"
                value={form.role.toString()}
                onChange={(e) => edit("role", e.currentTarget.value.toString())}
              >
                {roleOptions.map((opt,k) => <option key={opt.value} value={opt.value}>{opt.text}</option>)}
              </select>
              {_e(form.error.role)}
            </div>
            <div className="form-field">
              <label className="input-label" id="emailLabel" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                className="input-value"
                type="text"
                placeholder="Email"
                value={form.email}
                onChange={(e) => edit("email", e.currentTarget.value)}
              />
              {_e(form.error.email)}
                </div>
            <div className="form-field">
              <label className="input-label" id="employeeCodeLabel" htmlFor="employeeCode">
                Employee Code
              </label>
              <input
                id="employeeCode"
                className="input-value"
                type="text"
                placeholder="Employee Code"
                value={form.employeeCode}
                onChange={(e) => edit("employeeCode", e.currentTarget.value)}
              />

              {_e(form.error.employeeCode)}
            </div>
            <div className="form-field">
              <label
                className="input-label"
                id="passwordLabel"
                htmlFor="password"
              >
                Password
              </label>
              <input
                id="password"
                className="input-value"
                type="password"
                placeholder="Password"
                value={form.password}
                onChange={(e) => edit("password", e.currentTarget.value)}
              />
              {_e(form.error.password)}
            </div>
            <div className="form-field">
              <label
                className="input-label"
                id="password2Label"
                htmlFor="password2"
              >
                Confirm Password
              </label>
              <input
                id="password2"
                className="input-value"
                type="password"
                placeholder="Confirm password"
                value={form.confirm}
                onChange={(e) => edit("confirm", e.currentTarget.value)}
              />
              {form.confirm && form.confirm !== form.password && <span className="error-message">Passwords do not match</span>}
            </div>
            <div className="form-buttons">
              <button className="signup-button btn" type="submit">Save</button>
            </div>
          </React.Fragment>)}
      </form>
  );
};

export default RegisterPage;
