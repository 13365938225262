import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getWorkOrder, listVesselStatus, getDictionary } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../helpers/variableNotation";

import Detail from "../../vessel/edit/detail";
import PreProduction from "../../vessel/edit/preProduction";
import Production from "../../vessel/edit/production";
import PostProduction from "../../vessel/edit/postProduction";
//import schema from "./schema";
//import { deepCopy } from "../../../helpers/deepCopy";
//import { objProps } from "../../../helpers/variableNotation";
//import { getPwi, findProducts, getDictionary } from "../../../api/Api";

const OperateVessel = () => {
  const { id } = useParams();

  const [ vesselStatusList, setVesselStatusList ] = useState([]);

  const [form, setForm] = useState({
    saved: false,
    dictionary: {},
    error: {},
    data: {}
  });

  useEffect(() => {

    // TODO: change to get last product
    listVesselStatus({callbackSuccess: result => setVesselStatusList(result.data)});

    getWorkOrder({
      id,
      callbackSuccess: (result) => {
      setForm((currentForm) => {
          const fm = deepCopy(currentForm);
          fm.data = objSnakeToCamel(result.data);
          return fm;
        });
      },
    });
    const getDictionarySuccess = (result) => {
      setForm((currentForm) => {
        const fm = deepCopy(currentForm);
        fm.dictionary = {
          ppe: result.data.ppe,
          caution: result.data.caution,
          mixing: result.data.mixing
        };
        return fm;
      });
    };
    getDictionary({
      callbackSuccess: getDictionarySuccess,
    });
  }, [id]);

  const viewSettings = {
    mode: "admin",
    baseUrl: "/work-order/view"
  };

  return (
    <div id="process-start">
      <Detail form={form} vesselStatusList={vesselStatusList} />
      <PreProduction form={form} setForm={setForm} viewSettings={viewSettings} />
      {form?.data?.pwi?.production?.processes.map(( process, processNumber ) => <Production key={processNumber} form={form} setForm={setForm} viewSettings={viewSettings} processNumber={processNumber} />)}
      <PostProduction form={form} setForm={setForm} viewSettings={viewSettings} />
    </div>
  );
};

export default OperateVessel;
