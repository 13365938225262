import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { GlobalProvider } from "./contexts/global";
import { getCookie } from "./helpers/cookies";
import { getMe } from "./api/Api";
import { adminCanRead,
  adminPwiCanRead,
  adminProcessCanRead,
  adminUserCanRead
} from "./helpers/permissions";
import ScrollToTop from "./helpers/scrollToTop";
import Navigation from "./components/navigation/navigation";
//import Account from "./pages/account/account.component";
import Forgot from "./components/forgot/forgot";
import Reset from "./components/reset/reset";
import Login from "./components/login/login";
import Dashboard from "./components/dashboard/dashboard";
import WorkOrderView from "./components/workOrder/view/view";
import WorkOrderList from "./components/workOrder/list/list";
import WorkOrderEdit from "./components/workOrder/edit/edit";
import PwiEdit from "./components/pwi/edit/edit";
import PwiList from "./components/pwi/list/list";
import VesselEdit from "./components/vessel/edit/edit";
import VesselList from "./components/vessel/list/list";
import User from "./components/user/user";
import Start from "./components/startPwi/startProcess";
import Settings from "./components/settings/settings";
import NutriagLogo from "./components/common/logo.svg";

import("./styles/global.scss");

const App = () => {
  const [global, setGlobal] = useState({
    token: getCookie("token"),
    role: null,
  });

  const appType = window.location.hostname === process.env.REACT_APP_ADMIN_DOMAIN ? 'admin' : 'terminal';

  useEffect(() => {
    if(global.token && global.role === null) {
      const callbackSuccess = (result) => {
        if(!isNaN(result.data.role)) {
          setGlobal({ token: global.token, role: result.data.role, app: getCookie('app')});
        }
      };
      getMe({callbackSuccess});
    }
  }, [global]);

  const pageClass = (window.location.pathname.split('/').filter(path => path && path !== 'login').length > 0 && global.role)
    || (window.location.pathname === '/' && global.role && appType === 'admin')
    ? ' page' : '';
  return (
    <GlobalProvider value={{ ...global, setGlobal }}>
      <div className={"app-view " + appType + pageClass}>
        {'REACT_APP_DEV_MODE' in process.env && process.env.REACT_APP_DEV_MODE.toString() === "1" && (
          <div className="dev-mode">Development Mode</div>
        )}
        <Router>
          <ScrollToTop />
          {global.role === null && (
            <Switch>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          )}
          {[0,1,2,3].includes(global.role) && appType !== 'admin' && (
            <Switch>
              <Route path="/vessel/:id">
                <VesselEdit />
              </Route>
              <Route path="/dashboard">
                <VesselList />
              </Route>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          )}
          {adminCanRead(global.role) && appType === 'admin' && (
            <React.Fragment>
              <Navigation />
              <div className="heading-logo">
                <img src={NutriagLogo} alt="Nutriag" />
              </div>
              <div className="main-content">
                <Switch>
                  <Route path="/pwi/list/:query">
                    {adminProcessCanRead(global.role) && <PwiList />}
                  </Route>
                  <Route path="/pwi/:id?">
                    {adminProcessCanRead(global.role) && <PwiEdit />}
                  </Route>
                  <Route path="/work-order/list/:query">
                    {adminPwiCanRead(global.role) && <WorkOrderList />}
                  </Route>
                  <Route path="/work-order/view/:id">
                    {adminPwiCanRead(global.role) && <WorkOrderView />}
                  </Route>
                  <Route path="/work-order/:id?">
                    {adminPwiCanRead(global.role) && <WorkOrderEdit />}
                  </Route>
                  <Route path="/work-order">
                    {adminPwiCanRead(global.role) && <Start />}
                  </Route>
                  <Route path="/user/:id?">
                    {adminUserCanRead(global.role) && <User />}
                  </Route>
                  <Route path="/settings">
                    {adminUserCanRead(global.role) && <Settings />}
                  </Route>
                  <Route path="/reset">
                    <Reset />
                  </Route>
                  <Route path="/forgot">
                    <Forgot />
                  </Route>
                  <Route path="/dashboard">
                    {adminPwiCanRead(global.role) ? <Dashboard /> : <Redirect to="/pwi/list/published" /> }
                  </Route>
                  <Route path="/">
                    {adminPwiCanRead(global.role) ? <Dashboard /> : <Redirect to="/pwi/list/published" /> }
                  </Route>
                </Switch>
              </div>
            </React.Fragment>
          )}
        </Router>
      </div>
    </GlobalProvider>
  );
};

export default App;
