import React, { useState } from "react";
import { allStepsInOne } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import NumberPad from "./numberPad";

const ActionAllStepsInOne = ({processId, form, setForm, readOnly}) => {

  const process = form.data.processes.find(proc => proc.id === processId);

  const [ tempAllStepsInOne, setTempAllStepsInOne ] = useState(process.allStepsInOne);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showEmployeePad, setShowEmployeePad ] = useState(false);

  const processAllStepsInOneNow = (employeeCode) => {
    allStepsInOne({id: processId,
      employeeCode: employeeCode,
      allStepsInOne: tempAllStepsInOne,
      callbackFailure: result => setErrorMessage('Invalid Code'),
      callbackSuccess: result => {
        const fm = deepCopy(form);
        for(let x=0; x < fm.data.processes.length; x++) {
          if(fm.data.processes[x].id === processId) {
            fm.data.processes[x].allStepsInOne = tempAllStepsInOne;
          }
        }
        setForm(fm);
        setShowEmployeePad(false);
    }});
  };

  const changeAllStepsInOne = evt => {
    if(readOnly) return;
    setTempAllStepsInOne(evt.currentTarget.value);
    setShowEmployeePad(true);
  };

  const currentValue = !('allStepsInOne' in process) || process.allStepsInOne === null ? "-" : process.allStepsInOne.toString();

  return (
    <div className="vessel-step requires-complete">
      <div className="vessel-step-details">
        <h3>Can this step be completed on this shift</h3>
        <div className="">
          <input type="radio" value="1" checked={currentValue === "1"} id="all-step-yes" onChange={changeAllStepsInOne} />
          <label htmlFor="all-step-yes">Yes</label>
        </div>
        <div className="">
          <input type="radio" value="0" checked={currentValue === "0"} id="all-step-no" onChange={changeAllStepsInOne} />
          <label htmlFor="all-step-no">No</label>
        </div>
        <div className="other-list"><div className="other-list-title">*Supervisor Approval Required</div></div>
{/*
        <SwitchButton
          readOnly={preventEdit || isReadOnly(process, step.slug)}
          checked={getOption(process, step.slug, opt.slug).value}
          labels={opt.config?.terminal?.includes('switch-yes-no') ? lblYesNo : lblComplete}
          onChange={optionValue => saveStep({form, setForm, processMode:modes.preProduction, processName, stepSlug: step.slug, optionValue, optionSlug: opt.slug})}
        />
*/}
      </div>
      {showEmployeePad && <NumberPad onSubmit={processAllStepsInOneNow} closeNumberPad={()=>{setErrorMessage('');setShowEmployeePad(false);}} errorMessage={errorMessage} /> }
    </div>
  );
};
export default ActionAllStepsInOne;
