/*
@n str  field name
@o str  field value
@t int  days
*/
const setCookie = (n,o,t) => {let r=''; if(t){const a=new Date();a.setTime(a.getTime()+24*t*60*60*1e3); r="; expires="+a.toGMTString()}else r="";document.cookie=n+"="+o+r+"; path=/; domain=."+window.location.hostname+";"};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export { setCookie, getCookie }
