import { getStep } from "../helpers";
import { isStepComplete }  from "./completeStep";

const CompletedBy = (process, stepIndex) => {
  const step = getStep(process, stepIndex);
  return isStepComplete(process.steps, stepIndex) && (
    <div className="completed-by other-list">
      <span className="other-list-title">Done by:</span>
      <ul>
        <li>
          {step.completedByUser?.firstName}&nbsp;
          {step.completedByUser?.lastName}&nbsp;@&nbsp;
          {step.endTime}
        </li>
      </ul>
    </div>
  );
};

export default CompletedBy;
