import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SwitchButton from "../../common/switchButton";
import modes from "../../../config/productionModes";
import { initProcess } from "../../../api/Api";
import { saveStep, getOption } from "./helpers";
import { deepCopy } from "../../../helpers/deepCopy";
import PreviewMaterial from "./action/previewMaterial";
import CompleteStep, { isStepComplete } from "./action/completeStep";
import CompletedBy from "./action/completedBy";
import ApproveProcess from "./action/approveProcess";
import Notes from "./action/notes";
import ProcessSignOff from "./processSignOff";

const allBooleanOptionsCompleted = (process, step) => {
  const booleanOptions = enabledOptions(step).filter(opt => opt.type.terminal === 'boolean');
  return booleanOptions.length === booleanOptions.filter(opt => getOption( process, step.slug, opt.slug).value ).length;
};
const enabledOptions = step => step.options.filter(opt=> opt.value);

const PreProduction = ({ form, setForm, viewSettings }) => {
  const processName = 'preprod';
  const { data } = form;

  const process = data.processes ? data.processes.find(row => row.processMode === modes.preProduction && row.processName === processName) : null;

  useEffect(() => {
    if(data.id && !process) {
      initProcess({
        data:{
          workOrderId: data.id,
          processMode: modes.preProduction,
          processName,
        },
        callbackSuccess: result => {
          const fm = deepCopy(form);
          fm.data.processes.push({workOrderId: data.id, processMode: modes.preProduction, processName: processName, id: result.data, steps: []});
          setForm(fm);
        }
      })
    }
  }, [data.id, process, processName, form, setForm]);

  if(!(data.id && process)) {
    return null;
  }

  const successUrl = `${viewSettings.baseUrl}/${data.id}/production/0`;
  const actionApproveProps = {form, setForm, processId: process.id, successUrl};
  const actionDoneProps = { form, setForm, processName, processMode:modes.preProduction };
  const requiresApproval = !process.approvedBy && Boolean(parseInt(form.data.pwi.isOrganic));
  const preventEdit = viewSettings.mode === 'admin' || process.approvedBy;
  const lblComplete = {yes: (<span className="complete">Complete</span>), no: (<span className="not-complete">&nbsp;</span>)};
  const lblYesNo = {yes: (<span className="complete">Yes</span>), no: (<span className="not-complete">No</span>)};
  return (
    <React.Fragment>
      <h2>Pre-Production</h2>
      <div className="vessel-step-container pre-production">
        {data.pwi.preProduction
          .steps
          .filter(step => step.options.find(opt => opt.value) && !step.config?.terminal?.find(cnf => cnf.toLowerCase() === "ignore"))
          .map((step, i) => (
        <div key={i} className="vessel-step">
          <h3>{step.label}</h3>
          <div className="vessel-options">
          {enabledOptions(step).map((opt, j) => (
            <div key={j} className="vessel-option">
            {opt.type.terminal === "boolean" && (
              <React.Fragment>
                <label>{opt.label}</label>
                <SwitchButton
                  readOnly={preventEdit || isStepComplete(process.steps, step.slug)}
                  checked={getOption(process, step.slug, opt.slug).value}
                  labels={opt.config?.terminal?.includes('switch-yes-no') ? lblYesNo : lblComplete}
                  onChange={optionValue => saveStep({form, setForm, processMode:modes.preProduction, processName, stepSlug: step.slug, optionValue, optionSlug: opt.slug})}
                />
              </React.Fragment>
            )}
            {opt.type.terminal === "text-if-true" && <label>{opt.label}</label>}
            </div>
          ))}
          {CompletedBy(process, step.slug)}
          </div>
          { step.config &&
            step.config.terminal &&
            step.config.terminal.includes('requires-employee-code') &&
            allBooleanOptionsCompleted(process, step) &&
            (<CompleteStep {...actionDoneProps} stepSlug={step.slug} readOnly={preventEdit} />)
          }
        </div>
        ))}
        <Notes
          processId={process.id}
          form={form}
          setForm={setForm}
          readOnly={preventEdit}
        />
      </div>
      <PreviewMaterial form={form} processNum={0} />
      {viewSettings.mode !== 'admin' && (
      <div className="vessel-navigation-bottom">
        {!requiresApproval && <NavLink className="button next-step" to={successUrl}>Next Process &raquo;</NavLink>}
        {requiresApproval && <ApproveProcess {...actionApproveProps} />}
        <ProcessSignOff process={process} />
      </div>
      )}
    </React.Fragment>
  );
};
export default PreProduction;
