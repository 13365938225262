import SwitchButton from "../common/switchButton";

const StartProcess = ({ generateCode }) => {
  return (
    <div id="process-start">
      <h1>
        Start a <span> Process </span>{" "}
      </h1>

      <div>
        <label> Product </label>
        <select>
          <option>BoronMax (1234)</option>
          <option>Phosphorus (0987)</option>
          <option>BoronMin (5678)</option>
        </select>
      </div>

      <div>
        <label>Work Order</label>
        <input></input>
      </div>
      <div>
        <label>Batch Size</label>
        <input></input>
        <span>LT</span>
      </div>
      <div>
        <label>Batch Size</label>
        <input></input>
        <span>KG</span>
      </div>
      <div>
        <label>Vessel</label>
        <select>
          <option>R1</option>
          <option>R2</option>
          <option>R3</option>
          <option>R4</option>
          <option>R5</option>
        </select>
      </div>

      <div>
        <label>Previous Product Made</label>
        <input></input>
      </div>
      <div>
        <label>Schedule Date</label>
        <select>
          <option>Jan 1</option>
          <option>Jan 2</option>
          <option>Jan 3</option>
          <option>Jan 4</option>
        </select>
      </div>

      <div>
        <label>Time</label>
        <input></input>
        <SwitchButton />
      </div>

      <div>
        <button>Schedule</button>
        <button>Start Now</button>
      </div>
    </div>
  );
};

export default StartProcess;
