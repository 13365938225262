import { useState } from "react";
import { completeProcess } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import NumberPadWithToggle from "./numberPadWithToggle";

const ActionCompleteProcess = ({processId, form, setForm}) => {

  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }
  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  const completeNow = (employeeCode) => {
    completeProcess({id: processId,
      employeeCode,
      callbackFailure,
      callbackSuccess: result => {
        const fm = deepCopy(form);
        for(let x in fm.data.processes) {
          if(fm.data.processes[x].id === processId) {
            fm.data.processes[x].endTime = result.process.end_time;
            fm.data.processes[x].completedBy = result.user.id;
            fm.data.processes[x].completedByUser = {firstName: result.user.first_name, lastName: result.user.last_name};
          }
        }
        callbackSuccess(result);
        setForm(fm);
      }
    });
  }

  return <NumberPadWithToggle
            onSubmit={completeNow}
            errorMessage={errorMessage}
            isVisibleNumberPad={isVisibleNumberPad}
            closeNumberPad={closeNumberPad}
            openNumberPad={openNumberPad}
            btnText="Done"
            btnCssClass="next-step"
          />;
};
export default ActionCompleteProcess;
