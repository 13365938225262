import TableRow from "./tableRow";

const Table = ({ title, records }) => {
  return (
    <div className="table">
      <h2>{title}</h2>
      <table>
        <thead>
          <tr>
            <th>Vessel</th>
            <th>Product</th>
            <th>Reference #</th>
            <th>Start</th>
          </tr>
        </thead>
        <tbody>
          {records.map((rec, key) => (
            <TableRow key={key} data={rec} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
