import React, { useState } from "react";
import { deepCopy } from "../../../../helpers/deepCopy";

const deleter = {
  production: (form, setForm, pathHandle) => {
    const fm = deepCopy(form);
    fm.data.production.processes[pathHandle.processIndex].steps.splice(pathHandle.fieldIndex, 1);
    setForm(fm);
  },
  postProduction: (form, setForm, pathHandle) => {
    const fm = deepCopy(form);
    fm.data.postProduction.qualityControl.splice(pathHandle.fieldIndex, 1);
    setForm(fm);
  },
};

const DeleteStep = ({form, setForm, pathHandle}) => {

  const [ showConfirm, setShowConfirm ] = useState(false);
  return (
    <React.Fragment>
      {showConfirm && (
        <div className="modal">
          <div className="modal-content">
            <button type="button" className="modal-close" onClick={()=>setShowConfirm(false)}>&times;</button>
            <p>Are you sure you want to delete this step?</p>
            <div className="modal-control">
              <button type="button" onClick={()=>{
                deleter[pathHandle.mode](form, setForm, pathHandle);
                setShowConfirm(false);
              }}>Delete</button>
            </div>
          </div>
        </div>
      )}
      <button type="button" className="delete-step" onClick={()=>setShowConfirm(true)}>&times;</button>
    </React.Fragment>
  );

};

export default DeleteStep;
