import React, { useContext } from "react";
import { GlobalContext } from "../../../contexts/global";
import { Redirect, useParams } from "react-router-dom";
import { createProcess, updateProcess } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import { adminProcessCanPublish } from "../../../helpers/permissions";
import { objSnakeToCamel } from "../../../helpers/variableNotation";
import SortItems from "../../common/sortItems";
import Input from "./fields/input";
import Label from "./fields/label";
import AddQualityControl from "./addQualityControl";
import PwiNav from "./pwiNav";
import { isReadOnly } from "./helper";

const PostProduction = ({ form, setForm, role}) => {

  const { id } = useParams();

  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.saved = true;
    setForm(fm);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };
  const save = async () => {
    const saveToAPI = form.data.id ? updateProcess : createProcess;
    saveToAPI({data:{approve:1, ...form.data}, callbackSuccess, callbackFailure});
  }
  const renderStep = (item, fieldIndex) => {
    const fieldProps = {
      form,
      setForm,
      pathHandle: {
        mode: "postProduction",
        fieldIndex
      }
    };
    return (
      <React.Fragment>
        {["text","number","email"].includes(item.type.admin) && <Input {...fieldProps} textType={item.type.admin} />}
        {["label"].includes(item.type.admin) && <Label {...fieldProps} />}
      </React.Fragment>
    );
  };
  const setItems = (items) => {
    const fm = deepCopy(form);
    fm.data.postProduction.qualityControl = items;
    setForm(fm);
  };
  const prevUrlPieces = ["pwi"];
  if(id) {
    prevUrlPieces.push(id);
  }
  prevUrlPieces.push("production");
  if(form.data.production.processes.length) {
    prevUrlPieces.push(form.data.production.processes.length - 1);
  }

  const prevUrl = form.data.production.processes.length ? "/" + prevUrlPieces.join("/") : "/pwi/detail";
  const global = useContext(GlobalContext);
  const saveLabel = adminProcessCanPublish(global.role) ? "Publish" : "Submit for Approval";
  if(form.saved) {
    return <Redirect to="/pwi/list/published" />;
  }
  return (
    <div>
    <div className="post-production pwi-field-management">
      <h3>Quality Control Targets</h3>
      <div className="pwi-field-list">
        <AddQualityControl form={form} setForm={setForm} addLocation="start" />
        <SortItems
          items={form.data.postProduction.qualityControl}
          setItems={setItems}
          idHandle="slug"
          renderContent={renderStep}
        />
      </div>
      <AddQualityControl form={form} setForm={setForm} addLocation="end" />
    </div>
    <PwiNav prev={prevUrl} save={isReadOnly(form.data, role).length > 0 ? null : ()=>save()} saveLabel={saveLabel} />
    </div>
  );
};

export default PostProduction;
