import { saveProcessStep, incompleteProcessStep } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";

export const saveStep = ({form, setForm, processMode, processName, stepSlug, employeeCode, startTime, optionSlug, optionValue, callbackFailure, callbackSuccess}) => {
  const fm = deepCopy(form);
  const process = form.data.processes ? form.data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;
  const processIndex = fm.data.processes.findIndex(proc => proc.processMode === processMode && proc.processName === processName);

  let step = getStep(process, stepSlug);
  if(!step) {
    step = {
      processId: process.id,
      content: {slug: stepSlug, options: []}
    }
    fm.data.processes[processIndex].steps.push(step);
  }

  if(startTime) {
    step.startTime = startTime.toISOString().replace(/T/,' ').replace(/.\d\d\dZ/,'');
  }
  if(employeeCode) {
    step.employeeCode = employeeCode;
  }

  if(optionSlug) {
    if(!step.content.options.find(opt => opt.slug === optionSlug)) {
      step.content.options.push({slug: optionSlug, value: optionValue});
    }
    for(let x in step.content.options) {
      if(step.content.options[x].slug === optionSlug) {
        step.content.options[x].value = optionValue;
      }
    }
  }
  
  fm.data.processes[processIndex].steps[getStepIndex(process, stepSlug)] = step;
  setForm(fm);
  saveProcessStep({
    data:step,
    callbackFailure,
    callbackSuccess: result => {
      if(employeeCode) {
        step.endTime = (new Date()).toISOString();
        step.completedBy = 1;
        step.completedByUser = {
          firstName: result.completed_by_user.first_name,
          lastName: result.completed_by_user.last_name,
        };
      }
      step.content = result.process_step.content;
      // setting the id prevents the api from duplicating inserts
      if(!step.id) {
        step.id = result.data;
        // step.startTime = (new Date()).toISOString();
      }
      fm.data.processes[processIndex].steps[getStepIndex(process, stepSlug)] = step;
      setForm(fm);
      if(callbackSuccess) {
        callbackSuccess(result);
      }
    }
  });
};
export const completeStep = ({form, setForm, processMode, processName, stepSlug, employeeCode, callbackFailure, callbackSuccess}) => {

  const fm = deepCopy(form);
  const process = form.data.processes ? form.data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;
  const processIndex = fm.data.processes.findIndex(proc => proc.processMode === processMode && proc.processName === processName);

  let step = getStep(process, stepSlug);
  if(!step) {
    step = {
      processId: process.id,
      content: {slug: stepSlug, options: []}
    }
    fm.data.processes[processIndex].steps.push(step);
  }

  step.employeeCode = employeeCode;

  saveProcessStep({
    data:step,
    callbackFailure,
    callbackSuccess: result => {
      step.endTime = result.process_step.end_time;
      step.completedBy = result.process_step.completed_by;
      step.completedByUser = {
        firstName: result.completed_by_user.first_name,
        lastName: result.completed_by_user.last_name,
      };
      step.content = result.process_step.content;
      // setting the id prevents the api from duplicating inserts
      if(!step.id) {
        step.id = result.data;
        // step.startTime = (new Date()).toISOString();
      }
      fm.data.processes[processIndex].steps[getStepIndex(process, stepSlug)] = step;
      setForm(fm);
      if(callbackSuccess) {
        callbackSuccess(result);
      }
    }
  });
};

export const incompleteStep = ({form, setForm, processMode, processName, stepSlug, employeeCode, callbackFailure, callbackSuccess}) => {

  const fm = deepCopy(form);
  const process = form.data.processes ? form.data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;
  const processIndex = fm.data.processes.findIndex(proc => proc.processMode === processMode && proc.processName === processName);

  let step = getStep(process, stepSlug);
  if(!step) {
    return;
  }

  incompleteProcessStep({
    id: step.id,
    employeeCode,
    callbackFailure,
    callbackSuccess: result => {
      step.endTime = null;
      step.completedBy = null;
      step.completedByUser = {};
      step.content = result.process_step.content;
      fm.data.processes[processIndex].steps[getStepIndex(process, stepSlug)] = step;
      setForm(fm);
      if(callbackSuccess) {
        callbackSuccess(result);
      }
    }
  });
};
export const getStep = (process, stepSlug) => {
  return process.steps.find(step => step.content.slug === stepSlug);
};
export const getStepIndex = (process, stepSlug) => {
  return process.steps.findIndex(step => step.content.slug === stepSlug);
};
export const getOption = (process, stepSlug, optionSlug) => {
  const defaultOpt = {
    optionSlug,
    value: '',
  };
  const step = getStep(process, stepSlug);
  if(!step) {
    return defaultOpt;
  }
  const opt = step.content.options.find(opt => opt.slug === optionSlug);
  return opt ? opt : defaultOpt;
};
export const isReadOnly = (process, stepSlug) => {
  const step = getStep(process, stepSlug);
  if(!step) return false;

  return step.completedBy;
};
export const getTimeDiff = (dateA, dateB) => {

  const diff = (dateA.getTime() - dateB.getTime())/1000;
  const hrs = Math.floor(diff / 3600);
  const modHrs = diff % 3600;
  const mins = Math.floor(modHrs / 60);
  const secs = Math.floor(modHrs % 60);

  return `${hrs.toString().padStart(2,'0')}:${mins.toString().padStart(2,'0')}:${secs.toString().padStart(2,'0')}`;
};
