import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { findUsers } from "../../api/Api";

const UserMain = () => {

  const [ users, setUsers ] = useState([]);

  useEffect(() => {

    const callbackSuccess = (result) => {
      setUsers(result.data);
    };

    findUsers({callbackSuccess});
  },[]);
  return (
    <div id="display-users" className="table">
      <h2>Users</h2>
      <div className="next">
        <NavLink to="/user">Add a new User</NavLink>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {users.map(user => (
        <tr key={user.id}>
          <td>{user.first_name} {user.last_name}</td>
          <td>{user.role_name}</td>
          <td><NavLink to={"/user/" + user.id} className="icon-pencil"></NavLink></td>
        </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserMain;
