import React, { useState, useEffect } from "react";
import { findWorkOrders } from "../../api/Api";
import { deepCopy } from "../../helpers/deepCopy";
import Table from "./table";
import Statistics from "./statistics";

const longTimeAgo = '2000-01-01 00:00:00';

const Dashboard = () => {
  const [workOrdersActive, setWorkOrdersActive] = useState([]);
  useEffect(() => {
    const findWorkOrdersActiveSuccess = (result) => {
      setWorkOrdersActive(deepCopy(result.data));
    };
    const findWorkOrdersActiveFailure = (result) => {
      console.log(result);
    };
    findWorkOrders({
      query: `activate_time_gte=${longTimeAgo}&end_time=&cancel_time=`,
      callbackSuccess: findWorkOrdersActiveSuccess,
      callbackFailure: findWorkOrdersActiveFailure,
    });
  }, []);

  const [workOrdersScheduled, setWorkOrdersScheduled] = useState([]);
  useEffect(() => {
    const findWorkOrdersScheduledSuccess = (result) => {
      setWorkOrdersScheduled(deepCopy(result.data));
    };
    const findWorkOrdersScheduledFailure = (result) => {
      console.log(result);
    };
    findWorkOrders({
      query: `activate_time=&cancel_time=`,
      callbackSuccess: findWorkOrdersScheduledSuccess,
      callbackFailure: findWorkOrdersScheduledFailure,
    });
  }, []);

  return (
    <div>
      <Statistics />
      <Table title="Active" records={workOrdersActive} />
      <Table title="Scheduled" records={workOrdersScheduled} />
    </div>
  );
};

export default Dashboard;
