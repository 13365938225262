import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SwitchButton from "../../common/switchButton";
import modes from "../../../config/productionModes";
import { initProcess } from "../../../api/Api";
import { saveStep, getOption } from "./helpers";
import { deepCopy } from "../../../helpers/deepCopy";
import CompletedBy from "./action/completedBy";
import CompleteStep, { isStepComplete } from "./action/completeStep";
import CompleteWorkOrder from "./action/completeWorkOrder";
import Notes from "./action/notes";
import RetentionSample from "./action/retentionSample";
import ProcessSignOff from "./processSignOff";

const PostProduction = ({form, setForm, viewSettings}) => {
  const processName = 'postprod';
  const { data } = form;

  const process = data.processes ? data.processes.find(row => row.processMode === modes.postProduction && row.processName === processName) : null;

  useEffect(() => {
    if(data.id && !process) {
      initProcess({
        data:{
          workOrderId: data.id,
          processMode: modes.postProduction,
          processName,
        },
        callbackSuccess: result => {
          const fm = deepCopy(form);
          fm.data.processes.push({workOrderId: data.id, processMode: modes.postProduction, processName: processName, id: result.data, steps: []});
          setForm(fm);
        }
      })
    }
  }, [data.id, process, processName, form, setForm]);

  if(!(data.id && process)) {
    return null;
  }

  const actionDoneProps = { form, setForm, processName, processMode:modes.postProduction };

  const postProductionSteps = [
    {
      label: 'CIP Done After Batch Completion',
      slug: 'cip',
      options: [
        {enabled: 1, label: 'Hoses', slug: 'hoses'},
        {enabled: 1, label: 'Pumps', slug: 'pumps'},
        {enabled: 1, label: 'Tanks', slug: 'tanks'},
        {enabled: form.data.pwi.isOrganic, label: 'Hoses Inlet and Outlet', slug: 'hosesInletOutlet'},
        {enabled: form.data.pwi.isOrganic, label: 'Filter Unit', slug: 'filterUnit'},
        {enabled: form.data.pwi.isOrganic, label: 'Filter Bag Replaced', slug: 'filterBagReplaced'},
        {enabled: form.data.pwi.isOrganic, label: 'Hose Connection - Elbow Connection', slug: 'hoseConnectionElbow'},
        {enabled: form.data.pwi.isOrganic, label: 'New Tote for Storage', slug: 'newToteForStorage'}
      ]
    }
  ];
  const preventEdit = viewSettings.mode === 'admin' || process.approvedBy || process.completedBy;
  return (
    <React.Fragment>
      <div className="vessel-step-container post-production">
        <h2 className="vessel-process-title">Post Production</h2>
        <div className="vessel-step post-stats">
          <table>
            <thead>
              <tr>
                <th>Quality Control</th>
                <th>Target</th>
                <th>Actual Results</th>
              </tr>
            </thead>
            <tbody>
              {form.data.pwi.postProduction.qualityControl.map(field => (
                <React.Fragment key={field.slug}>
                {['text', 'number', 'email'].includes(field.type.terminal) && (
                  <tr>
                    <td>{field.label}</td>
                    <td>{field.value}</td>
                    <td>
                      {field.type.terminal !== 'read' &&
                        <input value={getOption(process, 'qualityControl', field.slug).value}
                          type={field.type.terminal}
                          pattern={field.type.terminal === 'number' ? '\\d*' : null}
                          readOnly={preventEdit || isStepComplete(process.steps, 'qualityControl') ? "readonly" : false}
                          onChange={preventEdit || isStepComplete(process.steps, 'qualityControl')  ? () => {} :
                            evt => saveStep({
                              form,
                              setForm,
                              processMode:modes.postProduction,
                              processName,
                              stepSlug: 'qualityControl',
                              optionValue: evt.currentTarget.value,
                              optionSlug: field.slug
                            })}
                        />}
                    </td>
                  </tr>
                )}
                {['quantityYield'].includes(field.type.terminal) && (()=>{
                  const val = getOption(process, 'qualityControl', field.slug).value;
                  const yld = val > 0 ? Math.round(10000*val/data.batchSizeKg)/100 : 0;
                  return (
                  <tr className="yield">
                    <td>{field.label}</td>
                    <td>{data.batchSizeKg}</td>
                    <td>
                      {field.type.terminal !== 'read' &&
                        <input value={val}
                          type="number"
                          pattern={field.type.terminal === 'number' ? '\\d*' : null}
                          readOnly={preventEdit || isStepComplete(process.steps, 'qualityControl')  ? "readonly" : false}
                          onChange={preventEdit || isStepComplete(process.steps, 'qualityControl')  ? () => {} :
                            evt => saveStep({
                              form,
                              setForm,
                              processMode:modes.postProduction,
                              processName,
                              stepSlug: 'qualityControl',
                              optionValue: evt.currentTarget.value,
                              optionSlug: field.slug
                            })}
                        />}
                      <span className="yield-pct">Yield: {yld > 0 ? yld+'%' : '--'}</span>
                    </td>
                  </tr>
                  );
                })()}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <CompleteStep {...actionDoneProps} stepSlug="qualityControl" readOnly={preventEdit} />
          {CompletedBy(process, "qualityControl")}
        </div>
        <RetentionSample
          processId={process.id}
          form={form}
          setForm={setForm}
          readOnly={preventEdit}
        />
        {postProductionSteps.map((step, i) => (
        <div key={i} className="vessel-step">
          <h2>{step.label}</h2>
          <div className="vessel-options">
          {step.options.filter(opt=> opt.enabled).map((opt, j) => (
            <div key={j} className="vessel-option">
              <label>{opt.label}</label>
              <SwitchButton readOnly={preventEdit || isStepComplete(process.steps, step.slug)} checked={getOption(process, step.slug, opt.slug).value} onChange={optionValue => saveStep({form, setForm, processMode:modes.postProduction, processName, stepSlug: step.slug, optionValue, optionSlug: opt.slug})}/>
            </div>
          ))}
            {CompletedBy(process, step.slug)}
          </div>
          <CompleteStep {...actionDoneProps} stepSlug={step.slug} readOnly={preventEdit} />
        </div>
        ))}
        <Notes
          processId={process.id}
          form={form}
          setForm={setForm}
          readOnly={preventEdit}
        />
      </div>
      {viewSettings.mode !== 'admin' && (
      <div className="vessel-navigation-bottom">
        <NavLink className="button previous-step" to={`/vessel/${data.id}/production/${data.pwi.production.processes.length - 1}`}>&laquo; Previous Process</NavLink>
        {!!process.retentionSample && <CompleteWorkOrder workOrderId={form.data.id} />}
        <ProcessSignOff process={process} />
      </div>
      )}
    </React.Fragment>
  );
};
export default PostProduction;
