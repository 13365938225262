import { beginWorkOrder } from '../../../../api/Api';
import { deepCopy } from "../../../../helpers/deepCopy";

const BeginWorkOrder = ({form, setForm, readOnly}) => {
  if(readOnly) {
    return null;
  }
  return (
    <div>
      <button type="button" className="btn-start-work-order"
        onClick={_ => beginWorkOrder({
          id: form.data.id,
          callbackSuccess: result => {
            const fm = deepCopy(form);
            // TODO: get the start time from api.result
            fm.data.startTime = (new Date()).toISOString().replace(/T/, ' ').replace(/.000Z/, '');
            setForm(fm);
          },
        })}>Start Work Order</button>
    </div>
  );
};
export default BeginWorkOrder;
