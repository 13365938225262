import React, { useState } from "react";

const NumberPad = ({onSubmit, errorMessage, closeNumberPad}) => {

  const [ codeValue, setCodeValue ] = useState('');

  return (
    <div className="modal modal-employee-code">
      <div className="modal-content">
        <input type="number" value={codeValue} onChange={e => setCodeValue(e.currentTarget.value)} />
        {errorMessage && <div className="error-dialog">{errorMessage}</div>}
        <div className="number-pad">
          {[1,2,3,4,5,6,7,8,9,0].map(n => <button key={n} className={`dial-${n}`} onClick={e => setCodeValue(codeValue.toString() + n.toString()) }>{n}</button>)}
          <button className="dial-empty-1">&nbsp;</button>
          <button className="dial-clear" onClick={_=>setCodeValue('')}>Clear</button>
        </div>
        <div className="modal-controls">
          <button onClick={_=>onSubmit(codeValue.toString()) }>Submit</button>
          <button onClick={_=>closeNumberPad()}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
export default NumberPad;
