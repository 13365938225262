import { useState, useEffect } from "react";
import { NavLink, Switch, Route, useParams } from "react-router-dom";
import { getWorkOrder, listVesselStatus, getDictionary } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../helpers/variableNotation";

import Detail from "./detail";
import PreProduction from "./preProduction";
import Production from "./production";
import PostProduction from "./postProduction";
import BeginWorkOrder from "./action/beginWorkOrder";
//import schema from "./schema";
//import { deepCopy } from "../../../helpers/deepCopy";
//import { objProps } from "../../../helpers/variableNotation";
//import { getPwi, findProducts, getDictionary } from "../../../api/Api";

import NutriagLogo from "../../common/logo.svg";

const OperateVessel = () => {
  const { id } = useParams();

  const [ vesselStatusList, setVesselStatusList ] = useState([]);

  const [form, setForm] = useState({
    saved: false,
    dictionary: {},
    error: {},
    data: {}
  });

  useEffect(() => {

    // TODO: change to get last product
    listVesselStatus({callbackSuccess: result => setVesselStatusList(result.data)});

    getWorkOrder({
      id,
      callbackSuccess: (result) => {
      setForm((currentForm) => {
          const fm = deepCopy(currentForm);
          fm.data = objSnakeToCamel(result.data);
          return fm;
        });
      },
    });
    const getDictionarySuccess = (result) => {
      setForm((currentForm) => {
        const fm = deepCopy(currentForm);
        fm.dictionary = {
          constants: result.data.constants,
        };
        return fm;
      });
    };
    getDictionary({
      callbackSuccess: getDictionarySuccess,
    });
  }, [id]);

  const viewSettings = {
    mode: "terminal",
    baseUrl: "/vessel"
  };

  return (
    <div className="vessel-view">
      <div className="vessel-navigation-top">
        <span className="vessel-current">
          <span>Vessel</span>
          <span className="vessel-number">{form.data.vessel}</span>
        </span>
        <NavLink to="/dashboard">All<br/>Vessels</NavLink>
      </div>
      <div className="heading-logo">
        <img src={NutriagLogo} alt="nutriag"/>
      </div>
      <Detail form={form} vesselStatusList={vesselStatusList} />
      <Switch>
        <Route path="/vessel/:id/production/:processNumber">
          <Production form={form} setForm={setForm} viewSettings={viewSettings} />
        </Route>
        <Route path="/vessel/:id/post-production">
          <PostProduction form={form} setForm={setForm} viewSettings={viewSettings} />
        </Route>
        <Route path="/vessel/:id">
          {form.data.startTime && <PreProduction form={form} setForm={setForm} viewSettings={viewSettings} />}
          {!form.data.startTime && <BeginWorkOrder form={form} setForm={setForm} />}
        </Route>
      </Switch>
    </div>
  );
};

export default OperateVessel;
