import React from "react";

const Detail = ({form, vesselStatusList}) => {
  const { data } = form ; 
  if(!data.id) {
    return null;
  }
  //const workOrder = vesselStatusList.find(row => row.vessel === data.vessel);
  const heating = data.pwi.preProduction
          .steps
          .filter(step => step.options.find(opt => opt.value) && step.slug === 'heating');

  return (
    <React.Fragment>
      <h2>Vessel {data.vessel} Product Details</h2>
      <div className="vessel-detail">
        <div className="vessel-info">
          <div>
            <span className="vessel-num">Vessel #:</span>
            <strong>{data.vessel}</strong>
          </div>
          <div>
            <h2>{data.pwi.productName}</h2>
          </div>
          <div>
            <span>Ref #:</span>
            <strong>{data.pwi.referenceNumber}</strong>
          </div>
        </div>

        <div className="vessel-detailed-info">
          <div>
            <strong>WO-Batch #</strong>
            <span>{data.workOrder}</span>
          </div>
          <div>
            <strong>Batch Size (LT)</strong>
            <span>{data.batchSizeLt}</span>
          </div>
          <div>
            <strong>Batch Size (KG)</strong>
            <span>{data.batchSizeKg}</span>
          </div>
          <div>
            <strong>Product Type</strong>
            <span>{data.pwi.isOrganic ? "Organic" : "Not Organic"}</span>
          </div>
        </div>

        {heating.length > 0 && (
          <div className="vessel-heating">
            <span>Temperature: </span>
            <strong>{heating[0]
              .options
              .filter(opt => opt.value)
              .map(opt => opt.label)
              .join(', ')
            }</strong>
          </div>
        )}

        <div className="vessel-previous-product">
          <span>Previous Product Made: </span>
          <strong>{data.recentVesselProducts.length > 0 ? data.recentVesselProducts[0].pwi.productName : '-'}</strong>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Detail;
