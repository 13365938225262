
const errorCodes = {
  "required": "Required",
  "min": "This field is too short",
  "login": "Unable to login",
  "email": "Invalid email",
  "email-taken": "Email is already taken",
  "employee-code-taken": "Employee code already taken"
};

const getErrorMessages = fieldErrors => {
  if(!fieldErrors) {
    return null;
  }
  return fieldErrors.map(code=>{
    const codeParts = code.split(':');
    const codeKey = codeParts[0];

    if(codeKey in errorCodes) {
      return errorCodes[codeKey];
    }

    return codeKey;
  });
};

const _es = fieldErrors => fieldErrors
  ? getErrorMessages(fieldErrors).map((message,key)=><span key={key} className="error-message">{message}</span>)
  : null;

const getErrorMessage = fieldErrors => {
  if(!fieldErrors) {
    return null;
  }
  const [ code ] = fieldErrors;
  const codeParts = code.split(':');
  const codeKey = codeParts[0];

  if(codeKey in errorCodes) {
    return errorCodes[codeKey];
  }
  return codeKey;
};


const _e = fieldErrors => fieldErrors
  ? <span className="error-message">{getErrorMessage(fieldErrors)}</span>
  : null;

export {_es, _e, getErrorMessages, getErrorMessage};
