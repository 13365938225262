import React, { useState } from "react";

const timer = 10000;

const getMaterial = (form,processNum) => {
  const processes = form.data?.pwi?.production?.processes;
  if(processes instanceof Array && processNum < processes.length) {
    return processes[processNum].material;
  }
  return null;
};


const PreviewMaterial = ({ form, processNum }) => {
  const [ showPreview, setShowPreview ] = useState(0);

  if(!getMaterial(form, processNum))
    return null;

  const closePreview = () => {
    clearTimeout(showPreview);
    setShowPreview(0);
  };
  const openPreview = () => {
    const t = setTimeout(()=>closePreview(),timer);
    setShowPreview(t);
  };
  if(showPreview > 0) {
    return (
      <div onClick={()=>closePreview()} className="modal modal-next-material">
        <div className="modal-content">
          <button type="button" className="modal-close" onClick={()=>closePreview()}>&times;</button>
          <h4 className="next-material-title">Next Material:</h4>
          <p className="next-material">{getMaterial(form, processNum)}</p>
        </div>
      </div>
    );
  }
  return (
    <div onClick={()=>openPreview()} className="vessel-navigation-bottom">
          <div className="vessel-step-details" style={{textAlign:'center'}}>
            <button type="button">Preview Next Material</button>
          </div>
    </div>
  );
};
export default PreviewMaterial;
