const Statistics = () => {
  return (
    <div id="pwi-status">
      <div className="pwi-section">
        <span className="pwi-count">14</span>
        <span>
          Active <span className="pwi"> PWi </span>
        </span>
      </div>
      <div className="pwi-section">
        <span className="pwi-count">18</span>
        <span>
          Most Recent <span className="pwi"> PWi </span>
        </span>
      </div>
      <div className="pwi-section">
        <span className="pwi-count">10</span>
        <span>
          Pending <span className="pwi"> PWi </span>
        </span>
      </div>
    </div>
  );
};
export default Statistics;
