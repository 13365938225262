import { deepCopy } from "../../../../helpers/deepCopy";
/*
pathHandle = {
  mode: production, preProduction, postProduction
  fieldIndex: integer
  processIndex: only for production integer
}
*/
export const fieldFinder = {
  production: (form, pathHandle)=> form.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex],
  postProduction: (form, pathHandle)=> form.data.postProduction.qualityControl[pathHandle.fieldIndex],
};

export const editor = {
  production: (value, form, setForm, pathHandle) => {
    const fm = deepCopy(form);
    fm.data.production.processes[pathHandle.processIndex].steps[pathHandle.fieldIndex].value = value;
    setForm(fm);
  },
  postProduction: (value, form, setForm, pathHandle) => {
    const fm = deepCopy(form);
    fm.data.postProduction.qualityControl[pathHandle.fieldIndex].value = value;
    setForm(fm);
  },
};
