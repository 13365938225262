import { Link } from "react-router-dom";

const PwiNav = ({prev, add, save, saveLabel, next}) => {

  return (
  <nav className={"pwi-nav" + (save ? " with-save" : "")}>
    <div className="prev-next">
    {prev && <Link to={prev}>Previous Step</Link>}
    {next && <Link to={next}>Next Step</Link>}
    </div>
    <div className="saving">
    {add && <button onClick={add} type="button">Add a Process</button>}
    {save && <button onClick={save} type="button">{saveLabel ? saveLabel : 'Save'}</button>}
    </div>
  </nav>
  );
};

export default PwiNav;
