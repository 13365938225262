const ProcessSignOff = ({process}) => {

  let signOff = [];
  if(process.completedBy && process.completedByUser) {
    signOff.push(<div key={process.completedBy+"c"} className="process-completed-by">
        Completed by {process.completedByUser.firstName} {process.completedByUser.lastName} @ {process.endTime}
      </div>
    );
  }
  if(process.approvedBy && process.approvedByUser) {
    signOff.push(<div key={process.approvedBy+"c"} className="process-approved-by">
        Approved by {process.approvedByUser.firstName} {process.approvedByUser.lastName} @ {process.approvalTime}
      </div>
    );
  }
  if(signOff.length > 0) {
    return <div className="sign-off">{signOff}</div>;
  }
  return null;
};
export default ProcessSignOff;
