import React, { useState } from "react";
import { getStep } from "../helpers";
import { addPpeEmployee } from "../../../../api/Api";
import { deepCopy } from "../../../../helpers/deepCopy";
import { objSnakeToCamel } from "../../../../helpers/variableNotation";
import NumberPadWithToggle from "./numberPadWithToggle";


export const savePpeEmployee = ({form, setForm, processMode, processName, stepSlug, employeeCode, callbackFailure, callbackSuccess}) => {

  const process = form.data.processes ? form.data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;

  let step = getStep(process, stepSlug);
  if(!step) {
    step = {
      processId: process.id,
      content: {slug: stepSlug, users:[]}
    }
  }
  addPpeEmployee({
    employeeCode,
    stepSlug,
    pwiProcessId: process.id,
    callbackFailure,
    callbackSuccess: result => {
      const resultCamel = objSnakeToCamel(result);
      const fm = deepCopy(form);
      for(let x in fm.data.processes) {
        if(fm.data.processes[x].processMode === processMode && fm.data.processes[x].processName === processName) {

          if(!step.id) {
            step = resultCamel.data;
            //step.startTime = (new Date()).toISOString();
            fm.data.processes[x].steps.push(step);
          } else {
            // re-apply the save --- not very intuitive, fix later
            for(let y in fm.data.processes[x].steps) {
              //if(fm.data.processes[x].steps[y].content.options.find(option => option.slug === optionSlug)) {
              if(fm.data.processes[x].steps[y].id === step.id) {
                fm.data.processes[x].steps[y] = resultCamel.data;
              }
            }
          }
        }
      }
      setForm(fm);
      callbackSuccess(result);
    }
  });
};

const AddPpeEmployee = ({ form, setForm, processName, processMode, stepSlug, readOnly }) => {

  const [ isVisibleNumberPad, setIsVisibleNumberPad ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const { data } = form;
  const process = data.processes ? data.processes.find(row => row.processMode === processMode && row.processName === processName) : null;
  let step = getStep(process, stepSlug);
  const renderKey = step && step.content && step.content.users ? `k${step.content.users.length}` : `k`;
/*
  const completed = process.steps.find(step => step.content.slug === stepSlug && step.completedBy);
  if(completed) {
    return <div className="vessel-action-done"><button type="button" className="readonly">Done</button></div>;
  }
*/
  const closeNumberPad = () => {
    setIsVisibleNumberPad(false);
  }
  const openNumberPad = () => {
    setIsVisibleNumberPad(true);
  }

  const callbackFailure = result => {
    setErrorMessage("Invalid Code");
  };
  const callbackSuccess = result => {
    closeNumberPad();
  };

  if(readOnly) {
    return null;
  }

  return (
    <div className="vessel-action-done">
      <NumberPadWithToggle btnCssClass="button-red" btnText="Done"
        key={renderKey}
        errorMessage={errorMessage}
        isVisibleNumberPad={isVisibleNumberPad}
        closeNumberPad={closeNumberPad}
        openNumberPad={openNumberPad}
        onSubmit={employeeCode => savePpeEmployee({
            form,
            setForm,
            processName,
            processMode,
            stepSlug,
            employeeCode,
            callbackFailure,
            callbackSuccess,
          })
        }
    />
    </div>
  );
};
export default AddPpeEmployee;
