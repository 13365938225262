import { useState, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { login } from "../../api/Api";
import { setCookie } from "../../helpers/cookies";
import { GlobalContext } from "../../contexts/global";
import { deepCopy } from "../../helpers/deepCopy";
import { _e } from "../../helpers/validation";

import Footer from "../../components/footer/footer";

const LoginPage = () => {
  const global = useContext(GlobalContext);
  const [form, setForm] = useState({
    email: "",
    password: "",
    error: {},
    redirect: "",
  });
/*
  useEffect(()=>{
    const fm = deepCopy(global);
    fm.role = null;
    fm.token = null;
    global.setGlobal(fm);
  }, [global]);
*/
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = {login:['login']};
    setForm(fm);
  };
  const callbackSuccess = (result) => {
    const { token, role } = result.data;
    const fm = deepCopy(form);
    fm.redirect = "/dashboard";
    setForm(fm);
    setCookie("token", token);
    global.setGlobal({ token, role });
  };
  const doLogin = (e) => {
    e.preventDefault();
    login({ ...form, callbackSuccess, callbackFailure });
  };
  const edit = (fieldName, val) => {
    const fm = deepCopy(form);
    fm[fieldName] = val;
    setForm(fm);
  };

  return (
    <div className="login-page">
      <form onSubmit={doLogin} className="login-form">
        <div className="form-head">
          {form.redirect && <Redirect to={form.redirect} />}
          <h1 className="head-text">Login</h1>
        </div>
        <div className="form-body">
          <div className="container">
            <input
              className="email-input"
              type="text"
              placeholder="Email"
              value={form.email}
              onChange={(e) => edit("email", e.currentTarget.value)}
            />
            <input
              className="password-input"
              type="password"
              placeholder="Password"
              value={form.password}
              onChange={(e) => edit("password", e.currentTarget.value)}
            />
            { _e(form.error.login) }
            <div className="form-buttons">
              <Link style={{display:'none'}} className="forgot-password-link" to="forgot">
                Forgot password?
              </Link>
              <button className="btn" type="submit">
                Sign in
              </button>
            </div>
          </div>
        </div>
        <section className="footer-section">
          <Footer />
        </section>
      </form>
    </div>
  );
};

export default LoginPage;
