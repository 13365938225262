import React from "react";
import { useParams } from "react-router-dom";
import { deepCopy } from "../../../helpers/deepCopy";
import PwiNav from "./pwiNav";
import SortItems from "../../common/sortItems";
import defaultSteps from "./defaultSteps";
/*
const isLiquid = (code) => {
  return code && code.toUpperCase().match(/^FLB/);
};
*/
const Detail = ({ form, setForm }) => {

  const { id } = useParams();
/*
  const cloneProcess = (code, materialName) => {
    const fm = deepCopy(form);
    const product = form.products.find(prod => prod.productKey === fm.data.productKey);
    const newProcess = product.production.find( material => material.code === code && material.material === materialName );
    const newIndex = fm.data.production.processes.length;
    newProcess.uniqueKey = newProcess.code + "-" + newIndex;
    newProcess.steps = isLiquid(newProcess.code)
      ? deepCopy(defaultSteps)
      : deepCopy(defaultSteps.filter(row => !(row.config
        && row.config.admin
        && row.config.admin.includes("liquid")))
      );
    if(newProcess) {
      fm.data.production.processes.push(newProcess);
      setForm(fm);
    }
  };
*/
  const changeProduct = (value) => {
    const product = form.products.find(prod => prod.productKey === value);
    const fm = deepCopy(form);
    fm.data.versionNote = "";
    fm.data.productKey = value;
    fm.data.productName = product.productName;
    fm.data.referenceNumber = product.referenceNumber;
    fm.data.filter = product.filter;
    fm.data.bom = product.bom;
    fm.data.production.processes = product.production.map((process, index) => {
      const steps = deepCopy(defaultSteps);
      const row = {
        steps,
        material: process.material,
        code: process.code,
        uniqueKey: process.code + "-" + index
      };
      return row;
    });
    setForm(fm);
  }

  const changeField = (fieldName, value) => {
    const fm = deepCopy(form);
    fm.data[fieldName] = value;
    setForm(fm);
  };

  const setItems = (items) => {
    const fm = deepCopy(form);
    fm.data.production.processes = items;
    setForm(fm);
  };
  const getDefaultVersionNumber = () => {
    const d = new Date();
    return '1-'+d.getFullYear()+(d.getMonth()+1).toString().padStart(2,"0")+d.getDate().toString().padStart(2,"0");
  };
  const nextUrl = id ? "/pwi/" + id + "/pre-production" : "/pwi/pre-production";
  //const renderAction = (row,index) => <button onClick={() => cloneProcess(row.code, row.material)}>clone {row.material}</button>;
  const renderAction = null;
  return (
    <React.Fragment>
      <div id="detail-input">
        <div>
          <label htmlFor="productName">Product Name</label>
          {isNaN(id) ? (
          <select id="productName" required="required" value={form.data.productKey} onChange={e => changeProduct(e.currentTarget.value)}>
            <option>-- Select Product--</option>
            {form.products.map(option => <option key={option.productKey} value={option.productKey}>{option.productName} - ref: {option.referenceNumber}</option>)}
          </select>
          ) : (
            <span>{form.data.productName}</span>
          )}
        </div>
        {form.data.productKey && (
        <React.Fragment>
        <div className="form-field">
          <label htmlFor="bom">Version</label>
          <span>{form.data.versionNumber ? form.data.versionNumber : getDefaultVersionNumber()}</span>
        </div>
        <div className="form-field">
          <label htmlFor="versionNote">Version Note</label>
          <input id="versionNote" type="text" value={form.data.versionNote} onChange={e => changeField('versionNote', e.currentTarget.value)}/>
        </div>
        <div className="form-field">
          <label htmlFor="isOrganic">Organic?</label>
          <select id="isOrganic" value={form.data.isOrganic} onChange={e => changeField('isOrganic', e.currentTarget.value)}>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="bom">BOM</label>
          <span>{form.data.bom}</span>
        </div>
        <div className="form-field">
          <label htmlFor="referenceNumber">Reference #</label>
          <span>{form.data.referenceNumber}</span>
        </div>
        <div className="form-field process">
          <label>Processes</label>
          <div className="sortable-material">
            <p>Please sort these processes in order before continuing</p>
            <SortItems
              items={form.data.production.processes}
              setItems={setItems}
              idHandle="uniqueKey"
              renderContent={(row,index) => row.material}
              renderActions={renderAction}
              />
          </div>
        </div>
        </React.Fragment>
        )}
      </div>
      {form.data.productName && <PwiNav next={nextUrl} />}
    </React.Fragment>
  );
};

export default Detail;
