import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { createWorkOrder, getWorkOrder, findProcesses } from "../../../api/Api";
import { deepCopy } from "../../../helpers/deepCopy";
import { arrSnakeToCamel, objSnakeToCamel } from "../../../helpers/variableNotation";
import vessels from "../../../config/vessels";

const makeProcessName = (productName, bom, ref) => [productName, bom, ref].join(' / ');

const getProcessNames = processes => {
  if(!processes) {
    return [];
  }
  const procNames = processes.reduce((carry, row)=>{
    const procName = makeProcessName(row.productName, row.bom, row.referenceNumber);
    if(!carry.includes(procName)) {
      carry.push(procName);
    }
    return carry;
  },[]);
  return procNames;
};

const WorkOrderForm = () => {
  const { id } = useParams();

  const [form, setForm] = useState({
    saved: false,
    error: {},
    data: {
      processId: "",
      workOrder: "",
      batchSizeKg: "",
      vessel: "",
      scheduleTime: "",
    },
  });
  const [ processName, selectProcessName] = useState("");
  const [ processes, setProcesses ] = useState([]);

  useEffect(() => {

    findProcesses({query: 'is_archived=0&approved=1', callbackSuccess: (result) => {
      setProcesses(arrSnakeToCamel(result.data));
    }});  
    if (!isNaN(id)) {

      getWorkOrder({
        id,
        callbackSuccess: (result) => {
          selectProcessName(makeProcessName(result.data.pwi.product_name, result.data.pwi.bom, result.data.pwi.reference_number));
          setForm((currentForm) => {
            const fm = deepCopy(currentForm);
            fm.data = objSnakeToCamel(result.data);
            return fm;
          });
        },
      });
    }
  }, [id]);

  const edit = (fieldName, value) => {
    const fm = deepCopy(form);
    fm.data[fieldName] = value;
    setForm(fm);
  };
  const callbackSuccess = () => {
    const fm = deepCopy(form);
    fm.saved = true;
    setForm(fm);
  };
  const callbackFailure = (result) => {
    const fm = deepCopy(form);
    fm.error = objSnakeToCamel(result.data);
    setForm(fm);
  };
  const save = async () => {
    createWorkOrder({ data: form.data, callbackSuccess, callbackFailure });
  };
  return (
    <div className="form-page">
      <h1>{typeof id === 'undefined' ? "Start PWI" : "Edit PWI"}</h1>
      {/* TODO: write proper CSS styles for this */}
      {form.saved && <Redirect to="/work-order/list/scheduled" />}
      <div className="form-field">
          <label htmlFor="processName">Process</label>
          <select
            id="processName"
            onChange={(e) => selectProcessName(e.currentTarget.value)}
            value={processName}
          >
            <option>-- Select --</option>
            {getProcessNames(processes).map(opt => <option key={opt} value={opt}>{opt}</option>)}
          </select>
      </div>
      <div className="form-field">
          <label htmlFor="processId">Version</label>
          <select
            id="processId"
            onChange={(e) => edit("processId", e.currentTarget.value)}
            value={form.data.processId}
          >
            <option>-- Select --</option>
            {processes.filter(opt => makeProcessName(opt.productName, opt.bom, opt.referenceNumber) === processName).map(opt => <option key={opt.id} value={opt.id}>{opt.versionNumber + (opt.versionNote ? ' - ' + opt.versionNote : '')}</option>)}
          </select>
      </div>
      <div className="form-field">
          <label htmlFor="workOrder">Work Order</label>
          <input
            id="workOrder"
            type="text"
            onChange={(e) => edit("workOrder", e.currentTarget.value)}
            value={form.data.workOrder}
          />
      </div>
      <div className="form-field">
          <label htmlFor="batchSizeKg">Batch Size KG</label>
          <input
            id="batchSizeKg"
            type="number"
            onChange={(e) => edit("batchSizeKg", e.currentTarget.value)}
            value={form.data.batchSizeKg}
          />
      </div>
      <div className="form-field">
          <label htmlFor="vessel">Vessel</label>
          <select
            id="vessel"
            onChange={(e) => edit("vessel", e.currentTarget.value)}
            value={form.data.vessel}
          >
            <option>-- Select --</option>
            {vessels.map(opt => <option key={opt.slug} value={opt.slug}>{opt.text}</option>)}
          </select>
      </div>
      <div className="form-field">
          <label htmlFor="scheduleTime">Schedule Time</label>
          <input
            id="scheduleTime"
            onChange={(e) => edit("scheduleTime", e.currentTarget.value)}
            value={form.data.scheduleTime.replace(/[T\s]?\d\d:\d\d:\d\d/,'')}
            type="date"
          />
      </div>
      {isNaN(id) && (
      <button type="button" onClick={() => save()}>
        Save
      </button>)}
    </div>
  );
};

export default WorkOrderForm;
